import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {
  Typography,
  IconButton,
  DialogContent,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import {
  AlertProps,
  __tenantid__,
  dbName,
  fetchData,
  getFacilityId,
  personRequests,
  urls,
} from "../../../utils";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "../../../utils/theme";
import { DevTool } from "@hookform/devtools";
import { AlertContext } from "../../../context";

const schema = yup.object().shape({
  LastVisitDate: yup.string(),
  DialysisDate: yup.string().required("Dialysis Date is required"),
  HDMachineNo: yup.mixed().required(),
  striptest: yup.mixed().required("Strip Test is requi  red"),
  TargetDryWeight: yup
    .number("It must be a valid number")
    .required("Target Dry Weight is required")
    .integer()
    .positive()
    .min(0, "Minimum value is 0")
    .max(250, "Maximum value is 250"),
  TargetUF: yup
    .number()
    .required("Target UF is required")
    .typeError("It must be a valid number")
    .min(0, "Minimum value is 0")
    .max(10, "Maximum value is 10"),
  PreHDWeight: yup
    .number()
    .integer()
    .positive()
    .required("Pre HD Weight is required")
    .typeError("It must be a valid number")
    .min(0, "Minimum value is 0")
    .max(250, "Maximum value is 250"),
  PrevPostHDWeight: yup
    .number()
    .integer()
    .positive()
    .typeError("It must be a valid number")
    .required("Previous Post HD Weight is required")
    .min(0, "Minimum value is 0")
    .max(250, "Maximum value is 250"),
  IDWG: yup.number().required().typeError("It must be a valid number"),
  Shift: yup.mixed().required("Shift is required"),
  Frequency: yup.mixed().required("Frequency is required"),
  Duration: yup
    .number()
    .integer()
    .positive()
    .required("Duration is required")
    .typeError("It must be a valid number")
    .min(0, "Minimum value is 0")
    .max(12, "Maximum value is 12"),
  Qb: yup
    .number()
    .required("Qb is required")
    .typeError("It must be a valid number")
    .min(150, "Minimum value is 150")
    .max(500, "Maximum value is 500"),
  Qd: yup
    .number()
    .required("Qd is required")
    .typeError("It must be a valid number")
    .min(150, "Minimum value is 150")
    .max(500, "Maximum value is 500"),
  AVFneedlesize: yup.mixed().required("AVF Needle Size is required"),
  DialyzerType: yup.mixed().required("Dialyzer Type is required"),
  SurfaceArea: yup
    .number()
    .required("Surface Area is required")
    .typeError("It must be a valid number")
    .min(0, "Minimum value is 0")
    .max(5, "Maximum value is 5"),
  DialysateCa2: yup.mixed().required("Dialysate Ca2 is required"),
  Noofreuse: yup.string().required("Number of Reuse is required"),
  Type: yup.mixed().required("Type is required"),
  Loading: yup.string().required(),
  Maintenance: yup.string().required("Maintenance is required"),
  StaffID: yup.mixed().required("Staff ID is required"),
});

const TreatmentChart = props => {
  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues:{
      LastVisitDate:"",
      DialysisDate:"",
      HDMachineNo:"",
      striptest:"",
      TargetDryWeight:null,
      TargetUF:null,
      PreHDWeight:null,
      PrevPostHDWeight:null,
      IDWG:null,
      Shift:"",
      Frequency:"",
      Duration:null,
      Qb:null,
      Qd:null,
      AVFneedlesize:"",
      DialyzerType:"",
      SurfaceArea:null,
      DialysateCa2:"",
      Noofreuse:"",
      Type:"",
      Loading:"",
      Maintenance:"",
      StaffID:""
      }
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    control,
    getValues,
    reset,
  } = form;
  // const [treatChart,settreatChart] = React.useState({
  // LastVisitDate:"",
  // DialysisDate:"",
  // HDMachineNo:"",
  // striptest:"",
  // TargetDryWeight:"",
  // TargetUF:"",
  // PreHDWeight:"",
  // PrevPostHDWeight:"",
  // IDWG:"",
  // Shift:"",
  // Frequency:"",
  // Duration:"",
  // Qb:"",
  // Qd:"",
  // AVFneedlesize:"",
  // DialyzerType:"",
  // SurfaceArea:"",
  // DialysateCa2:"",
  // Noofreuse:"",
  // Type:"",
  // Loading:"",
  // Maintenance:"",
  // StaffID:""
  // });

  // const [open, setopen] = React.useState(true);
  const [DropDownVal, setDropDownVal] = React.useState([]);



  const onSubmit = data => {
    console.log(data, "onSubmit");
    props?.settreatChart(data)
    props?.setisMasterSave(true)
    alertContext.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: `Treatment Chart ${props?.treatChart ? "Updated" : "Added"}`,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    //props?.setistreatChart(true)
    //setopen(true);
  };

  const getDropDownValues = async () => {
    const payload = {
      db_name: dbName,
      filter: {
        type: [
          "DIALYSATE",
          "DIALYZERHIGHFLUX",
          "FLOWRATEAVFNEEDLESIZE",
          "HEPARINTYPE",
          "SHIFTTYPE",
          "DAYTYPE",
        ],
        tenantid: "1234",
        facilityid: "",
        lang: "en",
      },
      queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: urls?.readQdmQueries,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios.request(config)
      .then((response) => {
        const transformedData = response?.data?.reduce((acc, curr) => {
          const key = Object.keys(curr)[0];
          acc[key] = curr[key]; 
          return acc;
        }, {});
        console.log(transformedData,"transformedData()()")
        setDropDownVal(transformedData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getDropDownValues();
  }, []);
  React.useEffect(() => {
    if(props?.treatChart){
      console.log(props?.treatChart,"props?.treatChart")
      Object.keys(props?.treatChart).forEach(key => {
        setValue(key, props?.treatChart[key]);
      });
    } else {
      if (props?.defaultValues) {
        const data = {
          LastVisitDate: props?.defaultValues?.LastVisitDate
            ? new Date(props?.defaultValues?.LastVisitDate * 1000)
                .toISOString()
                .split("T")[0]
            : "",
          DialysisDate: props?.defaultValues?.DialysisDate
            ? new Date(props?.defaultValues?.DialysisDate * 1000)
                .toISOString()
                .split("T")[0]
            : null,
          HDMachineNo: props?.defaultValues?.HDMachineNo?.value,
          TargetDryWeight: props?.defaultValues?.TargetDryWeight,
          Shift: props?.defaultValues?.shift?.value,
          Frequency: props?.defaultValues?.frequency?.value,
          Duration: props?.defaultValues?.hd_duration,
          Qb: props?.defaultValues?.qb,
          Qd: props?.defaultValues?.qd,
          AVFneedlesize: props?.defaultValues?.needleSize?.value,
          DialyzerType: props?.defaultValues?.DialyzerType?.value,
          DialysateCa2: props?.defaultValues?.dialysate?.value,
          Type: props?.defaultValues?.Heparin?.value,
          Loading: props?.defaultValues?.HeparinLoading?.value,
          Maintenance: props?.defaultValues?.Maintenance?.value,
        };
        Object.keys(data).forEach(key => {
          setValue(key, data[key]);
        });
        console.log(data,"datadata")
      }
    } 
  }, [props?.defaultValues,props?.treatChart]);

  const getIdwgWeight = () => {
    let preHDWeights = getValues("PreHDWeight");
    let prevPostHDWeights = getValues("PrevPostHDWeight");
    if (!isNaN(preHDWeights) && !isNaN(prevPostHDWeights)) {
      let calvalue = preHDWeights - prevPostHDWeights;
      // let idwgValue = Math.floor((calvalue / dryweights) * 100)
      setValue("IDWG", calvalue, { shouldDirty: true, shouldTouch: true });
      trigger("IDWG");
    }
  };

  console.log(DropDownVal?.HEPARINTYPE?.Value,"{{{")

  return (
    <Paper
      elevation={5}
      sx={{
        padding: "10px",
        margin: "20px",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 6 }}
          margin={1}
        >
          <Grid item xs={2.3} marginLeft={2}>
            <Typography variant="h6">TREATMENT CHART</Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item xs={1.5}>
              <TextField
                id="outlined-basic"
                label="Last Visit Date"
                variant="outlined"
                size="small"
                type="date"
                {...register("LastVisitDate")}
                name="LastVisitDate"
                //onChange={(e)=>handleOnChange("LastVisitDate",e.target.value,"Date")}
                //value={treatChart?.LastVisitDate}
                InputLabelProps={{
                  shrink: true,
                }}
                // disabled={open}
                error={errors?.LastVisitDate?.message ? true : false}
                helperText={
                  errors?.LastVisitDate?.type === "required" || errors?.LastVisitDate?.type === "optionality"
                    ? ""
                    : errors?.LastVisitDate?.message
                }
                sx={{ width: 180, backgroundColor: "#FFFFFF" }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                id="outlined-basic"
                label="Dialysis Date"
                variant="outlined"
                size="small"
                type="date"
                {...register("DialysisDate")}
                name="DialysisDate"
                InputLabelProps={{
                  shrink: true,//getValues("DialysisDate") ? true : false,
                }}
                // onChange={(e)=>handleOnChange("DialysisDate",e.target.value,"date")}
                // value={treatChart?.DialysisDate}
                // disabled={open}
                error={errors?.DialysisDate?.message ? true : false}
                helperText={
                  errors?.DialysisDate?.type === "required" || errors?.DialysisDate?.type === "optionality"
                    ? ""
                    : errors?.DialysisDate?.message
                }
                sx={{ width: 180, backgroundColor: "#FFFFFF" }}
              />
            </Grid>
            <Controller
              name={"HDMachineNo"}
              control={control}
              render={({
                field: { ref, onChange, value, ...restField },
                formState,
              }) => (
                <Grid item xs={2.4}>
                  <Autocomplete
                    {...restField}
                    size="small"
                    id="HDMachineNo"
                    options={DropDownVal?.HDMACHINENO?.Value || []}
                    // isOptionEqualToValue={(Option, value) =>
                    //   Option?._id === value?._id
                    // }
                    sx={{ width: 300, backgroundColor: "#FFFFFF" }}
                    // disabled={open}
                    getOptionLabel={v => v?.display || v?.value || v}
                    fullWidth
                    value={value || null}
                    onChange={(_, data) => {
                      onChange(data?.display || data?.value);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                     
                        label="HD Machine No"
                        error={errors?.HDMachineNo}
                        helperText={
                          errors?.HDMachineNo?.type === "required" || errors?.HDMachineNo?.type === "optionality"
                            ? ""
                            : errors?.HDMachineNo?.message
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            />
            <Controller
              name={"striptest"}
              control={control}
              rules={{
                  validate: {required:(e)=>{
                    return true
                  }},
                }}
              render={({
                field: { ref, onChange, value, ...restField },
                fieldState: { error },
              }) => (
                <Grid item xs={2}>
                  <Autocomplete
                    {...restField}
                    size="small"
                    id="striptest"
                    options={props?.striptestList || []}
                    // isOptionEqualToValue={(Option, value) =>
                    //   Option?._id === value?._id
                    // }
                    // {...register('striptest')}
                    sx={{ width: 250, backgroundColor: "#FFFFFF" }}
                    // disabled={open}
                    getOptionLabel={v => v?.display || v?.name || v?.value || v}
                    fullWidth
                    value={value || null}
                    onChange={(_, data) => {
                      onChange(data?.display || data?.value || data?.name);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params} 
                        label="Strip Test By"
                        error={error}
                        helperText={
                          errors?.striptest?.type === "required" || errors?.striptest?.type === "optionality"
                            ? ""
                            : errors?.striptest?.message
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={2}>
              <Grid item xs={2.4}>
                <Paper sx={{ padding: "10px", margin: "10px" }}>
                  <Grid container rowSpacing={2} textAlign={"center"}>
                    <Grid item xs={12}>
                      <Typography variant="button">Weight</Typography>
                    </Grid>
                    <Divider></Divider>
                    <Grid item xs={12}>
                      <TextField
                        label="Target Dry Weight"
                        id="standard-start-adornment"
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        fullWidth
                        {...register("TargetDryWeight")}
                        // name="TargetDryWeight"
                        InputLabelProps={{
                          shrink: getValues("TargetDryWeight") ? true : false,
                        }}
                        // value={getValues('TargetDryWeight')}
                        // disabled={open}
                        error={errors?.TargetDryWeight?.message ? true : false}
                        helperText={
                          errors?.TargetDryWeight?.type === "required" || errors?.TargetDryWeight?.type === "optionality"
                            ? ""
                            : errors?.TargetDryWeight?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Target UF"
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        fullWidth
                        {...register("TargetUF")}
                        // value={treatChart?.TargetUF}
                        // onChange={(e)=>handleOnChange("TargetUF",e.target.value,"")}
                        InputLabelProps={{
                          shrink: getValues("TargetUF") ? true : false,
                        }}
                        helperText={
                          errors?.TargetUF?.type === "required" || errors?.TargetUF?.type === "optionality"
                            ? ""
                            : errors?.TargetUF?.message
                        }
                        // disabled={open}
                        error={errors?.TargetUF?.message ? true : false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Pre HD Weight"
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        fullWidth
                        {...register("PreHDWeight")}
                        // value={treatChart?.PreHDWeight}
                        // onChange={(e)=>handleOnChange("PreHDWeight",e.target.value,"")}
                        InputLabelProps={{
                          shrink: getValues("PreHDWeight") ? true : false,
                        }}
                        // disabled={open}
                        onBlurCapture={() => getIdwgWeight()}
                        error={errors?.PreHDWeight?.message ? true : false}
                        helperText={
                          errors?.PreHDWeight?.type === "required" || errors?.PreHDWeight?.type === "optionality"
                            ? ""
                            : errors?.PreHDWeight?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Prev Post HD Weight"
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        fullWidth
                        {...register("PrevPostHDWeight")}
                        // onChange={(e)=>handleOnChange("PrevPostHDWeight",e.target.value,"")}
                        // value={treatChart?.PrevPostHDWeight}
                        InputLabelProps={{
                          shrink: getValues("PrevPostHDWeight") ? true : false,
                        }}
                        // disabled={open}
                        error={errors?.PrevPostHDWeight?.message ? true : false}
                        helperText={
                          errors?.PrevPostHDWeight?.type === "required" || errors?.PrevPostHDWeight?.type === "optionality"
                            ? ""
                            : errors?.PrevPostHDWeight?.message
                        }
                        onBlurCapture={() => getIdwgWeight()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="IDWG (Pre last HDi/kg)"
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: "#FFFFFF" }}
                        fullWidth
                        {...register("IDWG")}
                        // onChange={(e)=>handleOnChange("IDWG",e.target.value,"")}
                        // value={treatChart?.IDWG}
                        InputLabelProps={{  
                          shrink: getValues("IDWG") ? true : false,
                        }}
                        disabled={true}
                        error={errors?.IDWG?.message ? true : false}
                        helperText={
                          errors?.IDWG?.type === "required" || errors?.IDWG?.type === "optionality"
                            ? ""
                            : errors?.IDWG?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2.4}>
                <Paper sx={{ padding: "10px", margin: "10px" }}>
                  <Grid
                    container
                    spacing={2}
                    textAlign={"center"}
                    flex={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                    alignSelf={"center"}
                  >
                    <Grid item xs={12}>
                      <Typography variant="button">HD Duration</Typography>
                    </Grid>
                    <Controller
                      name={"Shift"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Shift"
                            options={DropDownVal?.DAYTYPE?.Value || []}
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data?.display || data?.value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Shift"
                                error={errors?.Shift}
                                helperText={
                                  errors?.Shift?.type === "required" || errors?.Shift?.type === "optionality"
                                    ? ""
                                    : errors?.Shift?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />

                    <Controller
                      name={"Frequency"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Frequency"
                            {...register("Frequency")}
                            options={DropDownVal?.SHIFTTYPE?.Value || []}
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data?.display || data?.value);
                              //   debouncedCall(PayerTypeDependencyOperations, data, true);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Frequency (Week)"
                                error={errors?.Frequency}
                                helperText={
                                  errors?.Frequency?.type === "required" || errors?.Frequency?.type === "optionality"
                                    ? ""
                                    : errors?.Frequency?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Duration"
                        variant="outlined"
                        size="small"
                        // sx={{ width: 230 }}
                        fullWidth
                        {...register("Duration")}
                        // value={treatChart?.Duration}
                        // onChange={(e)=>handleOnChange("Duration",e.target.value,"")}
                        InputLabelProps={{
                          shrink: getValues("Duration") ? true : false,
                        }}
                        // disabled={open}
                        error={errors?.Duration?.message ? true : false}
                        helperText={
                          errors?.Duration?.type === "required" || errors?.Duration?.type === "optionality"
                            ? ""
                            : errors?.Duration?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Hrs</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2.4}>
                <Paper sx={{ padding: "10px", margin: "10px" }}>
                  <Grid container rowSpacing={2} textAlign={"center"}>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant="button">Flow Rate</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Qb"
                        variant="outlined"
                        size="small"
                        // sx={{ width: 230 }}
                        fullWidth
                        {...register("Qb")}
                        // value={treatChart.Qb}
                        // onChange={(e)=>handleOnChange("Qb",e.target.value,"")}
                        InputLabelProps={{
                          shrink: getValues("Qb") ? true : false,
                        }}
                        // disabled={open}
                        error={errors?.Qb?.message ? true : false}
                        helperText={
                          errors?.Qb?.type === "required" || errors?.Qb?.type === "optionality"
                            ? ""
                            : errors?.Qb?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              ml/min
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Qd"
                        variant="outlined"
                        size="small"
                        // sx={{ width: 230 }}
                        fullWidth
                        {...register("Qd")}
                        // value={treatChart?.Qd}
                        // onChange={(e)=>handleOnChange("Qd",e.target.value,"")}
                        InputLabelProps={{
                          shrink: getValues("Qd") ? true : false,
                        }}
                        // disabled={open}
                        error={errors?.Qd?.message ? true : false}
                        helperText={
                          errors?.Qd?.type === "required" || errors?.Qd?.type === "optionality"
                            ? ""
                            : errors?.Qd?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              ml/min
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Controller
                      name={"AVFneedlesize"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="AVFNeedleSize"
                            options={
                              DropDownVal?.FLOWRATEAVFNEEDLESIZE?.Value ||
                              []
                            }
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              console.log(data,"DialyzerType")
                              onChange(data?.display || data?.value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="AVF Needle Size"
                                error={errors?.AVFneedlesize}
                                helperText={
                                  errors?.AVFneedlesize?.type === "required" || errors?.AVFneedlesize?.type === "optionality"
                                    ? ""
                                    : errors?.AVFneedlesize?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2.4}>
                <Paper sx={{ padding: "10px", margin: "10px" }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant="button">Dialyzer</Typography>
                    </Grid>
                    <Controller
                      name={"DialyzerType"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="DialyzerType"
                            options={
                              DropDownVal?.DIALYZERHIGHFLUX?.Value || []
                            }
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data?.display || data?.value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Dialyzer Type"
                                error={errors?.DialyzerType}
                                helperText={
                                  errors?.DialyzerType?.type === "required" || errors?.DialyzerType?.type === "optionality"
                                    ? ""
                                    : errors?.DialyzerType?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Surface Area"
                        variant="outlined"
                        size="small"
                        // sx={{ backgroundColor: '#f0f0f0' }}
                        fullWidth
                        {...register("SurfaceArea")}
                        onChange={(e)=>{setValue("SurfaceArea",e.target.value); trigger('SurfaceArea')}}
                        // value={treatChart?.SurfaceArea}
                        InputLabelProps={{
                          shrink: getValues("SurfaceArea") ? true : false,
                        }}
                        // disabled={open}
                        error={errors?.SurfaceArea?.message ? true : false}
                        helperText={
                          errors?.SurfaceArea?.type === "required" || errors?.SurfaceArea?.type === "optionality"
                            ? ""
                            : errors?.SurfaceArea?.message
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">m²</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Controller
                      name={"DialysateCa2"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Dialysateca2"
                            options={DropDownVal?.DIALYSATE?.Value || []}
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data?.display || data?.value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Dialysate Ca2+"
                                error={errors?.DialysateCa2}
                                helperText={
                                  errors?.DialysateCa2?.type === "required" || errors?.DialysateCa2?.type === "optionality"
                                    ? ""
                                    : errors?.DialysateCa2?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Controller
                      name={"Noofreuse"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Noofreuse"
                            options={Array.from({ length: 16 }, (_, i) => i)}
                            // disabled={open}
                            getOptionLabel={v => v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="No of reuse"
                                error={errors?.Noofreuse}
                                helperText={
                                  errors?.Noofreuse?.type === "required" || errors?.Noofreuse?.type === "optionality"
                                    ? ""
                                    : errors?.Noofreuse?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2.4}>
                <Paper sx={{ padding: "10px", margin: "10px" }}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant="button">Heparin</Typography>
                    </Grid>
                    <Controller
                      name={"Type"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Frequency"
                            options={DropDownVal?.HEPARINTYPE?.Value || []}
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v => v?.display || v?.value || v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data?.display || data?.value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Type"
                                error={errors?.Type}
                                helperText={
                                  errors?.Type?.type === "required" || errors?.Type?.type === "optionality"
                                    ? ""
                                    : errors?.Type?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Controller
                      name={"Loading"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Loading"
                            options={Array.from(
                              { length: 20 },
                              (_, i) => (i + 1) * 500
                            ).map(String)}
                            // disabled={open}
                            getOptionLabel={v => v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Loading"
                                error={errors?.Loading}
                                helperText={
                                  errors?.Loading?.type === "required" || errors?.Loading?.type === "optionality"
                                    ? ""
                                    : errors?.Loading?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Controller
                      name={"Maintenance"}
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...restField },
                      }) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...restField}
                            size="small"
                            id="Maintenance"
                            options={Array.from(
                              { length: 20 },
                              (_, i) => (i + 1) * 500
                            ).map(String)}
                            // disabled={open}
                            getOptionLabel={v => v}
                            fullWidth
                            value={value || null}
                            onChange={(_, data) => {
                              onChange(data);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Maintenance"
                                error={errors?.Maintenance}
                                helperText={
                                  errors?.Maintenance?.type === "required" || errors?.Maintenance?.type === "optionality"
                                    ? ""
                                    : errors?.Maintenance?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                    <Controller
                      name={"StaffID"}
                      control={control}
                      render={({field}) => (
                        <Grid
                          item
                          xs={12}
                          alignContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Autocomplete
                            {...field}
                            size="small"
                            id="staffId"
                            options={props?.striptestList || []}
                            // isOptionEqualToValue={(Option, value) =>
                            //   Option?._id === value?._id
                            // }
                            // disabled={open}
                            getOptionLabel={v =>
                              v?.name || v?.display || v?.value || v
                            }
                            fullWidth
                            value={field?.value || null}
                            onChange={(_, data) => {
                              field?.onChange(data?.display || data?.value || data?.name);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Staff Id"
                                error={errors?.StaffID}
                                helperText={
                                  errors?.StaffID?.type === "required" || errors?.StaffID?.type === "optionality"
                                    ? ""
                                    : errors?.StaffID?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          {props?.treatmentPermission == "edit" ? (
            <Grid
              container
              justifyContent="flex-end"
              gap={2}
              style={{ marginRight: "10px" }}
            >
              {/* {open ? (
                <Grid item>
                  <Button variant="outlined" onClick={() => {setopen(false)}}>
                    Open
                  </Button>
                </Grid>
              ) : ( */}
                <>
                  <Grid item>
                    <Button variant="outlined" onClick={() => reset()}>
                      Clear
                    </Button>
                  </Grid>          
                  {/* <Grid item>
                    <Button variant="outlined" onClick={() => setopen(true)}>
                      cancel
                    </Button>
                  </Grid> */}
                  <Grid item>
                    <Button variant="outlined" type="submit">
                      {props?.treatChart ? "Update" : "Add"}
                    </Button>
                  </Grid>
                </>
              {/* )} */}
            </Grid>
          ):(<></>)}
        </Grid>
      </form>
    </Paper>
  );
};

export default TreatmentChart;
