import React from "react";
import LogoSvg from "../../components/svg/components/logoSvg";
import { UIColor } from "../../themes/theme";
import {
    Row,
    Col,
    Div,
    Icons,
    Text,
    Divider,
    Button
  } from "qdm-component-library";
  import ForsightLogo from "../../assets/img/png/ForsightLogo.jpeg";
  import mailIcon2 from "../../assets/img/svg/icons8-mail.svg";
  
const UnsecuredAppBar = () => {
  return (
    <Row
      key={"0"}
      alignItems="stretch"
      direction="row"
      justify="flex-start"
      spacing="2"
      style={{
        backgroundColor: "#17a99e",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 1px",
      }}
      id={"signIn-Row"}
    >
      <Col
        key={"0"}
        name="qdm-col-12"
        alignItems="center"
        direction="row"
        justify="space-between"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{
          height: "55px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        id={"signIn-Col"}
      >
        {/* <LogoSvg/> */}
        <img
          variant="rounded"
          letter="Logo"
          // src={Logo}
          src={ForsightLogo}
          alt="Logo"
          style={{
            //height: 40,
            width: 120,
            borderRadius: 2,
          }}
          // style={{
          //   height: 100,
          //   //width: 100,
          //   borderRadius: 4,
          // }}
        />
        <Div
          key={"1"}
          inLineStyles="[object Object]"
          className="qdm"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          id={"signIn-Div1"}
        >
         
         <img
          src={mailIcon2}
          alt="mailIcon2"
          style={{
           width: "18px",
            borderRadius: "2px",
            margin:"8px",
            backgroundColor: "#fff"
          }}
        
        />
          <Text
            className="pc_regular"
            key={"1"}
            name="reach.us@ainqa.com"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontSize: 16,
            }}
            id={"signIn-phone-Text"}
          />

          <Divider
            key={"2"}
            className=""
            inLineStyles="[object Object]"
            label=""
            borderColor=""
            textColor=""
            orientation="vertical"
            variant="middle"
            style={{ height: "24px", marginTop: "6px" }}
            id={"signIn-Divider-vertical"}
          />

          <Button
            className="pc_medium"
            key={"3"}
            type="button"
            variant="text"
            name="Support"
            style={{
              borderColor: UIColor.secondaryButtonColor.color,
              borderRadius: "8px",
              color: UIColor.secondaryButtonColor.color,
              backgroundColor: "#fff",
              height: "35px",
              lineHeight: 0,
              fontSize: 14,
              minWidth: 88,
              fontWeight: 500,
            }}
            id={"signIn-Button-Support"}
          />
        </Div>
      </Col>
    </Row>
  );
};

export default UnsecuredAppBar;