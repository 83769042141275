import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
//import { withRouter } from 'react-router-dom'
import { Grid, TextareaAutosize, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  Button,
  Select,
} from "qdm-component-library";
import {
  AlertProps,
  GetQueueData,
  __tenantid__,
  actionButtonClick,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import { UIColor } from "../../themes/theme";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import withAllContexts from "../../hoc/withAllContexts";
import DatePicker from "react-datepicker";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { QueueManagementContext } from "../../context";
import axios from "axios";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";
import calendarIcon from "../../assets/img/svg/icons8-calendar.svg";
import styles from "../../screens/patientRegistration/components/styles";
//import "react-datepicker/dist/react-datepicker.css";

// const styles = (theme) => ({
//   labelStyle: {
//     color: "#6F6F6F",
//     fontSize: "12px",
//     marginBottom: "6px",
//     fontFamily: `pc_regular !important`,
//   },
//   borderStyle: {
//     borderRadius: 30,
//     borderColor: "#E0E0E0",
//   },
//   select: {
//     marginTop: 16,
//     "& fieldset": {
//       borderColor: "#E0E0E0",
//       borderRadius: 8,
//     },
//     "& input": {
//       height: 19,
//     },
//   },
// });

const SampleFormPage = (props) => {
  const { labelStyle, borderStyle } = styles;
  const dispatch = useDispatch();
  const {
    allData,
    displayData,
    previousData,
    loader,
    previousQueue,
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const selector = useSelector((state) => {
    return {
      registeredBy: state?.DashboardSlice?.registeredby?.data || [],
      branchCode: state?.DashboardSlice?.branchCode?.data || [],
      sampleCollected: state?.DashboardSlice?.sampleCollected?.data || [],
    };
  });
  const [state, setState] = useState({
    date: "",
    collectedBy: "",
    externalLab: "",
    conditions: "",
    registeredBy: "",
    branchCode: "",
  });
  const onChangeState = (value, namee) => {
    if (namee === "externalLab") {
      setState((prevState) => ({
        ...prevState,
        externalLab: value?.value,
        branchCode: value?.code,
      }));
    } else {
      setState((prevState) => ({ ...prevState, [namee]: value }));
    }
  };

  const handleSubmit = async () => {
    setloader(true);
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    let error = false;
    Object.keys(state).forEach((v) => {
      if (v !== "conditions" && !Boolean(state[v])) {
        error = true;
      }
    });
    if (!error) {
      props.sampleFormClose();

      let ticket = [];
      let arraydata = props?.selectedSpeciment;
      let action = "";
      let queue = props?.selectedSpeciment?.queueid;
      if (props?.selectedTicket?.length > 0) {
        arraydata = props?.SelectedTicketValue;
        ticket = props?.selectedTicket;
      }
      let payload = {
        tenantid: decodedUserinfo?.tenantid,
        facilityid: orgid,
        patientid: "",
        encounterid: "",
        samplingdate: moment(state.date).unix(),
        //Number((moment(state.date).unix() / 1000).toFixed(0)),
        samplingby: state?.collectedBy ?? "",
        registeredby: state?.registeredBy || "",
        samplingcondition: state?.conditions,
        branchcode: state?.branchCode,
        branchname: state?.externalLab,
        orderdetails: [],
      };
      let orderdetails = [];
      arraydata.forEach((v) => {
          payload.encounterid = v?.inputdoc?.encounterid;
          payload.patientid = v?.inputdoc?.patientid;
          action = v?.action?.[0]?.action;
          queue = v?.queueid;
          orderdetails.push({
              testcode: v?.field10 || "",
              orderlineid: v?.inputdoc?.caorderlineid || "",
          });
          ticket.push({
              ticket: v?.ticketId || "",
          });
      });
      const ticketStrings = ticket.map(item => item.ticket);
      payload.orderdetails = orderdetails;
      let save_order_response = await dispatch(
        actions.SAVE_ORDER_SAMPLE(payload)
      );
      if (save_order_response?.payload?.data?.error) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: save_order_response?.payload?.data?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        let pua = await dispatch(
          actions.PERFORM_USER_ACTION({
            ticketId: ticketStrings,
            userAction: process.env.REACT_APP_LABORDER_SPECIMENTCOLLECTED,
          })
        );
        if (pua?.payload?.data?.error) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: pua?.payload?.data?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queue);
          props?.RefreshLatestQueue();
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Specimen collection saved successfully !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      }
      //  if(save_order_response?.pa)
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all fields !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    setloader(false);
  };
  const loadDefaultValues = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let payload = {
      screenid: process.env.REACT_APP_SAMPLE_COLLECTION_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localstoragekeyname:"userinfo",returnobjkeyname:"tenantid"}),
      filtervalues: {
        practitionerid: decodedUserinfo?.practitionerid,
      },
    };

    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (
        res?.data?.response?.screenId ===
        process.env.REACT_APP_SAMPLE_COLLECTION_SCREEN_ID
      ) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = ["date", "collectedBy"];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        let epochDate = stateValues?.date * 1000;
        let defaultDate = new Date(epochDate);
        setState({
          date: defaultDate,
          collectedBy: stateValues?.collectedBy?.value,
          externalLab: "",
          conditions: "",
          registeredBy: "",
          branchCode: "",
        });
      }
    }).catch((error) => {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  useEffect(() => {
    //onLoad();
    loadDefaultValues();
  }, []);

  const { cancelbookingreason, parent_id, classes } = props;
  console.log("lp", state);
  console.log("sampleprops", props);
  return (
    <div id={`${parent_id}_cancelbook_parent_div`}>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={
          {
            // padding: "20px",
          }
        }
      >
        <Typography style={{ fontSize: 20, color: "#101010" }}>
          Sample Collection Information
        </Typography>

        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
          //   style={qdmstyles.BKv}
        ></Divider>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
              Sample Collection
              <span
                style={{
                  color: "red",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "4px",
                }}
              >
                *
              </span>
            </p>
            <DatePicker
              onChange={(date) => onChangeState(date, "date")}
              selected={state?.date}
              showTimeSelect
              dateFormat="MM/dd/yyyy hh:mm"
              placeholderText="DD-MM-YYYY"
              className={"pc_regular"}
              showIcon
              icon="fa fa-calendar"
              withPortal
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id={`${parent_id}_patient_name_use_label_select`}
              value={state?.collectedBy}
              options={selector.sampleCollected || [{}]}
              // getOptionLabel={(options) => options?.name || {}}
              showArrow
              label="Sample Collected By"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChangeValue={(e) => onChangeState(e?.value, "collectedBy")}
              isRequired={true}
              inLineStyles={borderStyle}
              placeholder="select..."
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id={`${parent_id}_patient_name_use_label_select`}
              value={state?.externalLab ?? ""}
              options={selector?.branchCode || [{}]}
              //getOptionLabel={(options) => options?.name || {}}
              showArrow
              label="External Lab"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChangeValue={(e) => onChangeState(e, "externalLab")}
              isRequired={true}
              inLineStyles={borderStyle}
              placeholder="select..."
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id={`${parent_id}_patient_name_use_label_select`}
              value={state?.registeredBy}
              options={selector?.registeredBy || [{}]}
              //getOptionLabel={(options) => options?.name || {}}
              showArrow
              label="Requested By"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChangeValue={(e) => onChangeState(e?.value, "registeredBy")}
              isRequired={true}
              inLineStyles={borderStyle}
              placeholder="select..."
            />
            {console.log(selector?.registeredBy, "sea")}
          </Grid>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "450",
                margin: "0",
                color: "#6A7888",
                fontSize: "13px",
                marginBottom: "6px",
              }}
            >
              Sample Collection
            </p>
            <TextareaAutosize
              onChange={(e) => onChangeState(e.target.value, "conditions")}
              maxRows={8}
              style={{
                width: "100%",
                height: "50px",
                outline: "1px solid rgb(222, 229, 236) ",
                border: "0px",
                borderRadius: "8px",
                padding: "5px",
              }}
              value={state?.conditions ?? ""}
            />
          </Grid>
        </Grid>

        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            // display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
            marginRight: "10px",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          disabled={props?.signIn?.loading || props?.cancelAppointment?.loading}
          className="pc_medium"
          variant="outlined"
          style={{
            // display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            //backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={props?.sampleFormClose}
        >
          Cancel
        </Button>
      </Container>
    </div>
  );
};

export default withStyles(styles)(withAllContexts(SampleFormPage));
