import React, { createContext } from "react";
import { DrawerProps } from "../utils";
import { AlertProps, Themes } from "../utils/constants";

export const AlertContext = createContext({
  open: false,
  severity: AlertProps.severity.success,
  msg: "",
  vertical: AlertProps.vertical.top,
  horizontal: AlertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
});

export const RoleContext = createContext({
  role: null,
  setData: () => null,
});

export const DrawerMeetingContext = React.createContext({
  open: false,
  direction: DrawerProps.direction.right,
  variant: DrawerProps.variant.temporary,
  RoomName: "",
  jwt: "",
  widthAdjState: "side",
  user_name: "You",
  onClose: () => null,
  setMeetingDrawer: () => null,
});

export const QueueManagementContext = React.createContext({
  allData: [],
  data: [],
  onHoldData: [],
  previousData: [],
  displayData: [],
  displayPreviousData: [],
  previousQueue:"",
  ActiveTab:0,
  HeaderTabActive:0,
  setAllData: () => {},
  setData: () => {},
  setOnHoldData: () => {},
  setPreviousData: () => {},
  setDisplayData: () => {},
  setDisplayPreviousData: () => {},
  setpreviousQueue:() => {},
  setActiveTab:() => {},
  setHeaderTabActive:() => {}
});

export const VisitDetails = React.createContext({
  encounter: {},
  practitioner: {},
  device: {},
  helthcare: {},
  accompained: {},
  setVisitDetails: () => null,
});
export const ReferralDetails = createContext({
  letter: {},
  from: {},
  to: {},
  details: {},
  note: {},
  setReferralDetails: () => null,
});
export const FinancialFormContext = createContext({
  payer: {},
  validity: {},
  membership: {},
  financial: {},
  applicable: {},
  savedata: [],
  index: null,
  setFinancialData: () => null,
});
export const DataPayloadContext = createContext({
  additnaldata: {},
  birthdetails: {},
  setadditnaldata: () => null,
});

export const VisitFormContext = createContext({
  encounterInfo: {},
  bookingdetails: {},
  pactitioner: {},
  deviceDetails: {},
  reasonDetails: {},
  setvisitform: () => null,
});

export const PatientRegContext = createContext({
  identification: [],
  names: [],
  details: {},
  communication: {},
  contact: {},
  address: [],
  setPatientData: () => null,
});
export const NextOfKinContext = createContext({
  identification: [],
  names: [],
  details: {},
  contact: {},
  address: [],
  addformdetails: [],
  index: null,
  setNextOfKin: () => null,
});
export let DialogContext = React.createContext({
  open: true,
  title: "",
  body: "",
  positiveBtn: "Ok",
  negativeBtn: "Cancel",
  onOk: () => null,
  setDialog: () => null,
});
export let BackdropContext = React.createContext({
  open: true,
  message: "",
  setBackDrop: () => null,
});
export let PatientDetailsContext = React.createContext({
  bookFlag: {
    modifyBook: false,
    multiBook: false,
    singleBook: false, // while setting the flag.dont spread the flag values.keys mentioned here are for to know what is keyname
  },
  orgId: "",
  specialityId: [],
  practionerId: "",
  patientId: "",
  patientData: {},
  nextOfKinData: {},
  selectedUser: {},
  slotId: "",
  location: {},
  selectedSlot: {},
});
export let SetPatientDetailsContext = React.createContext(() => null);

export const CheifComplaintsContext = React.createContext({
  list: [],
  editIndex: null,
  setCheifComplaints: () => null,
});

export const DiagnosisContext = React.createContext({
  list: [],
  editIndex: null,
  setDiagnosis: () => null,
});

export const VitalsContext = React.createContext({
  list: [],
  editIndex: null,
  isUpdate: null,
  setVitalsContext: () => null,
});
export const AllergyContext = React.createContext({
  list: [],
  editIndex: null,
  setAllergy: () => null,
});

export const MedicationContext = React.createContext({
  list: [],
  editIndex: null,
  setMedication: () => null,
});
export const TreatmentContext = React.createContext({
  list: [],
  editIndex: null,
  child: {},
  setTreatment: () => null,
});
export const LabOrderContext = React.createContext({
  list: [],
  editIndex: null,
  child: {},
  setLabOrder: () => null,
});
export const NursingProcedureContext = React.createContext({
  list: [],
  editIndex: null,
  child: {},
  setNursingProcedure: () => null,
});

export const PackageOrderContext = React.createContext({
  list: [],
  editIndex: null,
  child: {},
  setPackageOrder: () => null,
});

export const RadiologyContext = React.createContext({
  list: [],
  editIndex: null,
  setRadiology: () => null,
});

export const DoccumentContext = React.createContext({
  list: [],
  editIndex: null,
  setDoccument: () => null,
});

export const LoggerContext = React.createContext({
  getData: () => null,
  data: {},
  setLogData: () => null,
});

/**
 * ThemeContext store the current theme of the app,which is provided
 * at the /src/App.js using the /src/App.theme.js.
 */
export let ThemeContext = React.createContext({
  name: Themes.default,
  setTheme: () => null,
});

export const drawerContext =createContext({
  open: true,
  direction: DrawerProps.direction.right,
  variant: DrawerProps.variant.temporary,
  onClose: () => null,
  setDrawerState: ()=> null,
  isLarge: null,
  component: <>Hai</>,
})