import React, { useState } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EjectIcon from "@material-ui/icons/Eject";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
//import { withRouter } from "react-router-dom";
import Eye from "../../assets/img/eye.svg";
import CancelAppointment from "../../assets/img/cancel_appointment.svg";
import closeConsultation from "../../assets/img/closeConsultation.svg";
import Modify from "../../assets/img/modify.svg";
import { AppRoutes } from "../../router/routes";
import {
  withStyles,
  Popover,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import HighAlertPopUp from "../highAlertPopUp";
import iconHigh from "../../assets/img/svg/icons8-high priority red.svg";
import iconHighAlert from "../../assets/img/svg/icons8-high priority white.svg";
import { Divider as Divide } from "@material-ui/core";
import {
  QueueManagementContext,
  DrawerMeetingContext,
  VisitDetails,
} from "../../context";
//import { CamIcon, PersonIcon } from "../";
import {
  Divider,
  Text,
  Div,
  Image,
  H2,
  SideMenu,
  Avatar,
} from "qdm-component-library";
import {
  removeFromHold,
  pushToTop,
  AlertProps,
  getUtcTime,
  fetchData,
  axiosCall,
  actionButtonClick,
  personRequests,
  urls,
  getImgUrl,
  makeName,
  getFacilityId,
  getCalculatedAge,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { GetQueueData } from "../../utils";
import { useNavigate } from "react-router-dom";
import CancelBookingPage from "./cancelBooking";
import actions from "../../redux/actions";
import MultiBookComp from "../multibook/multiBookComp";
import UploadFiles from "./fileUpload";
import jwtDecode from "jwt-decode";
import Checkbox from "@mui/material/Checkbox";
import SampleFormPage from "./sampleForm";
const materialStyles = (theme) => ({
  // "& .tool":{
  //   display: "none",
  // },
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    width: "290px",
    height: "290px",
  },
  tooltip: {
    // display: "none",
    height: "auto",
    justifyContent: "center",
    background: "#444",
    borderRadius: "4px",
    color: "#fff",
    margin: "-26px auto 0",
    fontSize: "13px",
    padding: "1px 4px",
    width: "auto",
    position: "absolute",
  },
  main: {
    cursor: "pointer",
    padding: "6px",
    border: "1px solid #eee",
    borderRadius: "50%",
    marginRight: "4px",
    height: "30px",
    width: "30px",
    display: "flex",
    "& .tool": {
      display: "block !important",
    },
  },
  parentDiv: {
    "&:hover": {
      "& .reassign": {
        display: "block",
      },
      "& .highalert": {
        display: "block",
      },
    },
  },
});
const Queue = (props) => {
  const {
    allData,
    displayData,
    previousData,
    loader,
    previousQueue,
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  let JwtToken = localStorage.getItem("JwtToken");
  const performUserActionApi = process.env.REACT_APP_PERFORMUSERACTION_API;
  const [files, setFiles] = React.useState([]);
  const uploadDocument = (files) => {
    setFiles(files);
  };
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event, ticketId) => {
    if (event.target.checked) {
      let Arr = props?.selectedTicket ? [...props?.selectedTicket] : [];
      Arr.push(ticketId);
      let ArrayValue = props?.SelectedTicketValue
        ? [...props?.SelectedTicketValue]
        : [];
      let value = FilteredUsers.filter((li) => li?.ticketId === ticketId);
      if(ArrayValue.length > 0 && props?.duplicateCheck === value?.[0]?.personid){
        ArrayValue.push(value?.[0]);
        props?.setSelected(Arr, ArrayValue);
      }else if (ArrayValue.length === 0){
        ArrayValue.push(value?.[0]);
        props?.setSelected(Arr, ArrayValue);
        props?.setduplicateCheck(value?.[0]?.personid)
      }
      else {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Kindly select same patient",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    } else {
      let Arr = props?.selectedTicket.filter((item) => item !== ticketId);
      let value = props?.SelectedTicketValue.filter(
        (li) => li?.ticketId !== ticketId
      );
      props?.setSelected(Arr, value);
    }
    setChecked(event.target.checked);
  };
  const navigate = useNavigate();
  const [multiBookOpen, setMultiBookOpen] = React.useState(false);
  const [mBData, setMbData] = React.useState({});
  const [selectedQueueid, setSelectedQueueid] = React.useState("");
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  const { setVisitDetails } = React.useContext(VisitDetails);
  const dispatch = useDispatch();
  const [images, setImages] = React.useState([]);
  const [allMasters, setAllMasters] = useState([]);
  //   const uploadFun = (e, data) => {
  //     let list = images ? images : [];
  //     list.push(data)
  //     setImages(list)
  // }
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const [state, setState] = React.useState({
    view: null,
    modify: null,
    cancel: null,
    anchorEl: null,
    anchorE2: null,
    openDialog: null,
    openUpload: null,
    cancelbookingreason: "",
    cancelData: {},
    uploadData: {},
    spicemanCollectedData: {},
    cacellationType:process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE
  });
  const [noshow, setNoShow] = React.useState({
   reason:"",
   comments:""  //common for both cancellation n no show
});
// const [cancelcheckedin, setCancelCheckedIn] = React.useState({
//   reasondesc:""
// });
  const handleHighAlertPop = (event) => {
    setState({ ...state, anchorEl: event.target });
  };
  const handleHighAlertPopClose = () => {
    setState({ ...state, anchorEl: null });
  };
  const handlePopoverClose = () => {
    setState({ ...state, anchorE2: null });
  };
  const handlePopoverOpen = (event) => {
    setState({ ...state, anchorE2: event.target });
  };
  const handlePriority = async (list, _key, app, dropdownValue) => {
    const upsertdata = await dispatch(
      actions.UPSERT_PRIORITIES({ list, _key })
    );
    const masterData = [...props.queueManagement.data];
    const newData = [...props.queueManagement.displayData];
    const newOnHoldData = [...props.queueManagement.onHoldData];
    let masterDatavariable = masterData.find((d) => d.token === app[0].id);
    let newDataVariable = newData.find((d) => d.token === app[0].id);
    let newOnHoldDataVariable = newOnHoldData.find(
      (d) => d.token === app[0].id
    );
    if (masterDatavariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      masterDatavariable["patientPriority"] = patientPriority;
    }
    if (newDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newDataVariable["patientPriority"] = patientPriority;
    }
    if (newOnHoldDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newOnHoldDataVariable["patientPriority"] = patientPriority;
    }
    props.queueManagement.setData(masterData);
    props.queueManagement.setDisplayData(newData);
    props.queueManagement.setOnHoldData(newOnHoldData);
    //window.location.href = "/";
    const patientData = await dispatch(
      actions.HIGH_PRIORITY_PATIENT({
        _role: props.role_ === "nurse" ? 2 : 1,
        practionerId: loggedUserInfo?.data?.practionerId,
        org_id: loggedUserInfo?.data?.org_id,
        startDate: getUtcTime(moment().startOf("day")),
        endDate: getUtcTime(moment().endOf("day")),
      })
    );
  };
  const getEpochTIme = (date) => {
    const unixMilliSeconds = date * 1000;
    const myDate = new Date(unixMilliSeconds);
    return myDate.toLocaleString();
  };
  // componentDidMount() {}
  // shouldComponentUpdate(props, state) {
  //   return true;
  // }
  const setIsShown = (n, v) => {
    if (!n && !v) {
      setState({
        view: null,
        modify: null,
        cancel: null,
      });
      return;
    }
    setState({
      [n]: v,
    });
  };

  const getAllMasters = async () => {
   
    const response = await dispatch(actions.GET_REASON_MASTERS({type:["NOSHOWREASON"]}));

    setAllMasters(response?.payload?.data);
  };
  React.useEffect(() => {
    getAllMasters();
  }, []);
 
  const {
    isHold,
    type,
    showBtn,
    profile_pic,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10,
    field11,
    field12,
    queue,
    role,
    token,
    isEmergency,
    time,
    id,
    parent_id,
    markHighPriority,
    assignReassignPractitioner,
    totalData,
    nocancelVisit,
    nomodifyVisit,
    noviewVisit,
    action,
    ticketId,
    name,
    queueId,
    statesid,
    SelectedTicketValue,
    FilteredUsers,
    date,
    date1,
  } = props;
  const { classes } = props;
  const open = Boolean(state.anchorEl);
  const open1 = Boolean(state.anchorE2);
  const priorityTotalData = {
    key: totalData?.encounter,
    patientPriority: totalData?.patientPriority,
    highPriority:
      totalData?.patientPriority?.priority?.value?.length > 0 ? true : false,
    appointmentId: [totalData?.info],
  };
  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse" || app[0]?.type === "Nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor" || app[0]?.type === "Doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
    // await dispatch(
    //   actions.UPDATE_APPOINTMENT_STATUS({
    //     appointmentId,
    //     status,
    //     isCompleted,
    //   })
    // );
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
      },
    });
  };
  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: action?.action == "VISIT REGISTER" ? false : true,
          isRevise: action?.action == "REVISE VISIT" ? true : false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };
  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid,
    queue,
    queueId
  ) => {
    //setloader(true);
    if (queue === process.env.REACT_APP_REFERRAL_QUEUE) {
      if (action === "") {
      } else if (
        action === process.env.REACT_APP_REFERRAL_CANCEL ||
        action === process.env.REACT_APP_REFERRAL_ON_HOLD ||
        action === process.env.REACT_APP_REFERRAL_REJECT
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else if (action === process.env.REACT_APP_REFERRAL_UPLOAD_CONSENT) {
        setState({
          openUpload: true,
          uploadData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal.error) {
          setloader(true);
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else if (queue === process.env.REACT_APP_DIALYSIS_QUEUE) {
      if (action == "") {
      } else if (
        action === process.env.REACT_APP_REFERRAL_CANCEL ||
        action === process.env.REACT_APP_DIALYSIS_PAUSE ||
        action === process.env.REACT_APP_DIALYSIS_NO_SHOW ||  action === process.env.REACT_APP_DIALYSIS_CHECKED_IN_CANCEL
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else if (queue === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE) {
      if (action == "") {
      } else if (
        action === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE_CANCEL_ADMINISTER
      ) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else if (queue === process.env.REACT_APP_REFUND_QUEUE) {
      if (action == "") {
      } else if (action === process.env.REACT_APP_REFUND_REJECT) {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal?.error) {
          setloader(true);
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queueId);
          setloader(false);
        }
      }
    } else {
      if (action == "") {
      } else if (action === "CANCEL") {
        setState({
          openDialog: true,
          cancelData: {
            action: action,
            apiEndPoint: apiendpoint,
            backendCall: backEndCall,
            ticketId: ticketid,
            queue: queue,
            queueId: queueId,
          },
        });
      } else {
        let fetchVal = await actionButtonClick(
          action,
          apiendpoint,
          backEndCall,
          ticketid,
          queue,
          queue,
          queueId
        );
        if (!fetchVal.error) {
          setloader(true);
          let data = await GetQueueData();
          setAllData(data.everyData);
          setDisplayData(data.getDisplayData);
          setPreviousData(data.getPreviousData);
          setpreviousQueue(queueId);
          setloader(false);
        }
      }
    }
    //setloader(false);
  };
  const handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      setState(() => ({ openDialog: false }));
    } else if (id === "uploadClose") {
      setState(() => ({ openUpload: false }));
    }
  };
  const handlecahngeCancelbooking = async () => {
    setState(() => ({ openDialog: false }));
    let fetchVal = await actionButtonClick(
      state?.cancelData?.action,
      state?.cancelData?.apiEndPoint,
      state?.cancelData?.backendCall,
      state?.cancelData?.ticketId,
      state?.cancelbookingreason?._id,
      state?.cancelData?.queue,
      noshow?.reason?.value,
      noshow?.comments,
      state?.cacellationType,
      props?.totalData?.inputdoc?.appointmentid,
     
    );
    if (!fetchVal.error) {
      setloader(true);
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(state?.cancelData?.queueId);
      setloader(false);
    }
  };
  const handleSelectCancelbooking = (v,key) => {
    
    setState((prevState) => ({
      ...prevState,
      [key]: v,
    }));
  };
  const redirectScreen = (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId
  ) => {
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
      },
    });
  };
  const redirectTreatmentPlan = (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId,
    queueId,
    mrn,
  ) => {
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
        referralId: app?.inputdoc?.referralTrxId
          ? app?.inputdoc?.referralTrxId
          : app?.inputdoc?.referralid,
        mrnId : mrn
      },
    });
    setpreviousQueue(queueId);
  };
  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + " Yrs"
    );
  };
  const redirectBilling = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId,
    actionName,
    queueId
  ) => {

    const facilityId = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"});

    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);
    const queryData = personRequests.getDetailsAgainstPatientSearchByIsCashier(
      startEpochTime,
      endEpochTime,
      "",
      app?.field2,
      "",
      "",
      "",
      "",
      "",
      facilityId,
      0,
      10
    );
    var lista = {};
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    if (!SearchUserData || SearchUserData.length === 0) {
      console.log("No user data found.");
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Billing data not avaliable",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      SearchUserData?.forEach((v, i) => {
        Object.assign(lista, {
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.code,
          address: v?.address,
          age: getCalculatedAge(v?.birthDate),
          gender: v?.gender,
          email:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ?? "",
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${
            (v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
          }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          encounter: v?.encounter,
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
              ?.value ||
            v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?._id,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });
      navigate(AppRoutes.billingDetails, {
        state: {
          encounter_id,
          personId,
          patientId,
          appointmentId,
          encounterId,
          type,
          pId,
          app_id: app?.info?._id,
          ticketId,
          allCurrentData: app,
          actionName,
          ...lista,
        },
      });
    }
    setpreviousQueue(queueId);
  };
  const redirectVisitRegsitration = (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app,
    ticketId,
    action,
    queue,
    queueId
  ) => {
    navigate(AppRoutes.visitRegistration, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
        stateAction: action?.action,
        stateEndPoint: action?.apiendpoint,
        stateBackEndCall: action?.backEndCall,
        stateTicketid: ticketId,
        queue,
      },
    });
    setpreviousQueue(queueId);
  };
   const redirectPaymentCollection = (
    totalData,
    queueId
  ) => {
    navigate(AppRoutes.paymentCollections, {
      state: {
        patientid: totalData?.inputdoc?.patientid,
        _id: totalData?.inputdoc.refundid,
        isRefund:true
      },
    });
    setpreviousQueue(queueId);
  };
  const handleMultiBookData = (data, queueId) => {
    if (data) {
      setMbData(data);
    }
    setMultiBookOpen(!multiBookOpen);
    setSelectedQueueid(queueId);
  };
  const handleApprove = async (patientId, queueId) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: patientId?.inputdoc?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    navigate(AppRoutes.patientRegistration, {
      state: {
        isViewMode: true,
        data: patientInfo?.payload?.data,
        totalData: patientId,
      },
    });
    setpreviousQueue(queueId);
  };
  const handleEdited = async (patientId, queueId) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: patientId?.inputdoc?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    navigate(AppRoutes.patientRegistration, {
      state: {
        isEditedMode: true,
        data: patientInfo?.payload?.data,
        totalData: patientId,
      },
    });
    setpreviousQueue(queueId);
  };
  const printForm = async (totalData, queueId) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
        "@orgid": decodedUserinfo?.facilityid,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(queueId);
    }
  };
  const referralPrint = async (totalData, queueId) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_REFERRAL_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(queueId);
    }
  };
  const uploadImage = async (image) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      ReferralTrxId: totalData?.inputdoc?.referralTrxId, //"ReferralTrx/10050",
      attachments: [
        {
          _id: "",
          fileName: image?.viewFileName,
          fileid: "",
          filetype: image?.fileType,
          base64string: image?.openFile,
        },
      ],
      ticketId: state?.uploadData?.ticketId,
      practitionerId: decodedUserinfo?.practitionerid,
      practitionerRoleId: decodedUserinfo?.roleid,
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_SAVE_REFERRAL_ATTACHMENT,
      header
    );
    handlesidebarCancelbooking("uploadClose");
    if (!reportGenerate?.error) {
      setloader(true);
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(state?.uploadData?.queueId);
      setloader(false);
    }
  };
  const nurseAdministration = async (
    totalData,
    queueId,
    action,
    apiendpoint,
    backEndCall
  ) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    let payloadList = { ticketId: totalData?.ticketId };
    await axiosCall(payloadList, urls.nurseAdminstration, header)
      .then((res) => {
        if (!res?.error) {
          actionButtonClickEvent(
            action,
            apiendpoint,
            backEndCall,
            totalData?.ticketId,
            queue,
            queueId
          );
          // redirectBilling(
          //   totalData?.personid,
          //   totalData?.inputdoc?.patientid
          //     ? totalData?.inputdoc?.patientid
          //     : totalData?.inputdoc?.patientId,
          //   totalData?.inputdoc?.appointmentid,
          //   totalData?.inputdoc?.encounterid,
          //   totalData?.inputdoc?.resource_type,
          //   totalData?.inputdoc?.patientid
          //     ? totalData?.inputdoc?.patientid
          //     : totalData?.inputdoc?.patientId,
          //   totalData?.inputdoc?.encounterid,
          //   totalData,
          //   totalData?.ticketId,
          //   action,
          //   queueId
          // );
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res?.Response?.[0]?.message ?? res?.Response?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };
  const stringconertedarray = (originalArray) => {
    const convertedArray = originalArray.map((element) => `'${element}'`);
    return `[${convertedArray.join(", ")}]`;
  };
  const printOrderRequestForm = async (totalData, queueId) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    let arraydata = [totalData];
    if (props?.selectedTicket?.length > 0) {
      arraydata = props?.SelectedTicketValue;
    }
    let payload = {
      "@patientid": "",
      "@encounterid": "",
      "@orderlineid": "",
      "@facilityid": orgid,
      "@tenantid": decodedUserinfo?.tenantid,
    };
    let orderlineid = [];
    arraydata.forEach((v) => {
      payload["@encounterid"] = v?.inputdoc?.encounterid;
      payload["@patientid"] = v?.inputdoc?.patientid;
      orderlineid.push(v?.inputdoc?.caorderlineid || "");
    });
    payload["@orderlineid"] = stringconertedarray(orderlineid);
    const data = {
      reportid: process.env.REACT_APP_PRINT_ORDER_FORM_REPORTID,
      inputparams: payload,
      result: [],
    };
    try {
      const reportGenerate = await axiosCall(
        data,
        process.env.REACT_APP_GENERATE_REPORT,
        header
      );
      if (!reportGenerate?.error) {
        window.location.href = reportGenerate?.downloadUrl;
        let data = await GetQueueData();
        setAllData(data.everyData);
        setDisplayData(data.getDisplayData);
        setPreviousData(data.getPreviousData);
        setpreviousQueue(queueId);
      }
    } catch (error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  return (
    <div id={`${parent_id}_div`}>
      {/* {JSON.stringify(this.props.val?.encounter_status)} */}
      <Div
        id={`${parent_id}_sub_div`}
        className={classes.parentDiv}
        style={{ ...qdmstyles.row, padding: 10 }}
      >
        {queueId == "queue/10025" &&
          ["queuestates/10088", "queuestates/10089"].includes(statesid) && (
            <Checkbox
              id={ticketId}
              checked={props?.selectedTicket.includes(ticketId)}
              onChange={(e) => handleChange(e, ticketId)}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        <Div
          id={`${parent_id}_sublevel_div`}
          style={{ ...qdmstyles.row, flex: 1 }}
        >
          {field9 ? (
            <Div id={`${parent_id}_avatar_div`}>
              <Avatar
                id={`${parent_id}_avatar_image`}
                src={field9}
                alt={field1 ?? "profilePic"}
                style={{
                  border: isEmergency
                    ? "1px dashed #FF4D4A"
                    : "3px solid #DEE5EC",
                  borderRadius: 8,
                  marginRight: 20,
                }}
              />
            </Div>
          ) : (
            <Div id={`${parent_id}_avatar_div`}>
              <Avatar
                id={`${parent_id}_${props.name?.[0]}_patientinfocard_avatar`}
                variant="rounded"
                style={{ backgroundColor: "#DEE5EC", marginRight: 20 }}
              >
                <Typography variant="h6" color="White">
                  {name?.[0]}
                </Typography>
              </Avatar>
            </Div>
          )}
          {/* {isEmergency && (
                <Div
                  id={`${parent_id}_emergency_div`}
                  style={qdmstyles.emergencyDiv}
                >
                  <Text
                    id={`${parent_id}_emergency_text`}
                    className="pc_medium"
                    style={qdmstyles.emergencyText}
                  >
                    EMERGENCY
                  </Text>
                </Div>
              )} */}
          <Div style={{maxWidth:"800px" ,  whiteSpace:"initial"}}>
            {field1 && (
              <H2
                id={`${parent_id}_previousNextBodyName_h2`}
                className="pc_semibold"
                style={{
                  margin: "0px",
                  fontSize: "14px",
                  marginBottom: "4px",
                  textAlign: "left",
                  //width: "100px",
                }}
              >
                {field1 ? field1 : "Name is Undefined"}&nbsp;&nbsp;
              </H2>
            )}
            {field2 && (
              <>
                {/* <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                /> */}
                <Text
                  name={field2 ?? false}
                  id={`${parent_id}_previousNextBodyRole_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  {field2 ?? ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field3 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field3 ? field3 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field4 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field4 ? field4 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field5 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field5 ? field5 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field6 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field6 ? field6 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field7 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field7 ? field7 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}{" "}
            {field8 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field8 ? field8 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field10 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                  //style={qdmstyles.tokenNumber}
                >
                  &nbsp;&nbsp;{field10 ? field10 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field11 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field11 ? field11 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {field12 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{field12 ? field12 : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {date && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{date ? getEpochTIme(date) : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            {date1 && (
              <>
                <Divide
                  direction="vertical"
                  style={{
                    display: "inline-block",
                    height: "15px",
                    width: "2px",
                    margin: "-2px 2.5px",
                    backgroundColor: "rgb(204, 204, 204)",
                  }}
                />
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  &nbsp;&nbsp;{date1 !== "" ? "Administered on " + getEpochTIme(date1) : ""}&nbsp;&nbsp;
                </Text>
              </>
            )}
            <Div>
              {props?.totalData?.role && (
                <Text
                  id={`${parent_id}_previousNextBodyRole_time_text`}
                  className="pc_regular"
                  style={qdmstyles.previousNextBodyRole}
                >
                  {props?.totalData?.role ? props?.totalData?.role : ""}
                </Text>
              )}
            </Div>
          </Div>
        </Div>
        {/* {field6 && (
          <Div
            id={`${parent_id}_token_number_div`}
            className="pc_medium"
            style={qdmstyles.appNumber}
          >
            {field6 ? field6 : ""}
          </Div>
        )} */}
        {props?.selectedTicket?.length == 0 &&
          action?.map((l, i) => {
            let svg = l.icon;
            let blob = new Blob([svg], { type: "image/svg+xml" });
            let url = URL.createObjectURL(blob);
            return (
              <Button
                className={"reassign"}
                style={{
                  marginLeft: "5px",
                  backgroundColor:l?.bgcolor === "" ? "" : l?.bgcolor,
                  borderColor:l?.bgcolor === "" ? "#179E8E" : "",
                  color: l?.bgcolor === "" ? "#179E8E" : "white" ,
                }}
                variant="outlined"
                // color={l?.bgcolor}
                size="small"
                onClick={
                  () => {
                    if (queue == "Appointment Queue") {
                      if (
                        l.action === "VISIT REGISTER" ||
                        l.action === "VIEW VISIT" ||
                        l.action === "REVISE VISIT" ||
                        l.action === "NURSE SIGNOFF" ||
                        l.action === "DOCTOR SIGNOFF"
                      ) {
                        if (
                          totalData?.consultationMode?.display ===
                            "Video Consultation" &&
                          !drawerMeetingContext?.open
                        ) {
                          navigate(AppRoutes.videoCall, {
                            state: {
                              appointmentId: totalData.inputdoc?.appointmentid,
                              name: loggedUserInfo?.data?.name?.text,
                              role: loggedUserInfo?.data?.role,
                              data: totalData,
                            },
                          });
                        } else {
                          if (
                            totalData?.resourcerole?.display == "nurse" ||
                            totalData?.resourcerole?.display == "doctor" ||
                            totalData?.resourcerole?.display == "Nurse" ||
                            totalData?.resourcerole?.display == "Doctor"
                          ) {
                            attendPatient(
                              totalData?.personid,
                              totalData?.inputdoc?.patientid
                                ? totalData?.inputdoc?.patientid
                                : totalData?.inputdoc?.patientId,
                              totalData?.inputdoc?.appointmentid,
                              totalData?.inputdoc?.encounterid,
                              totalData?.inputdoc?.resource_type,
                              totalData?.inputdoc?.patientid
                                ? totalData?.inputdoc?.patientid
                                : totalData?.inputdoc?.patientId,
                              totalData?.inputdoc?.encounterid,
                              totalData,
                              totalData?.ticketId
                            );
                          } else {
                            registerPatient(
                              totalData?.personid,
                              totalData?.inputdoc?.patientid,
                              totalData.inputdoc?.appointmentid,
                              totalData?.inputdoc?.patientid ? true : false,
                              totalData?.mobileNo,
                              l,
                              totalData?.info?.ticketid
                            );
                          }
                        }
                      } else {
                        actionButtonClickEvent(
                          l.action,
                          l.apiendpoint,
                          l.backEndCall,
                          ticketId,
                          queue
                        );
                      }
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_APPROVE
                    ) {
                      //  redirectScreen(
                      //   totalData?.personid,
                      //   totalData?.inputdoc?.patientid,
                      //   totalData.token,
                      //   totalData?.inputdoc?.encounterid,
                      //   totalData?.inputdoc?.resource_type,
                      //   totalData.pId,
                      //   totalData.encounter_id,
                      //   totalData,
                      //   totalData?.ticketid
                      //  )
                      handleApprove(totalData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_EDIT
                    ) {
                      //  redirectScreen(
                      //   totalData?.personid,
                      //   totalData?.inputdoc?.patientid,
                      //   totalData.token,
                      //   totalData?.inputdoc?.encounterid,
                      //   totalData?.inputdoc?.resource_type,
                      //   totalData.pId,
                      //   totalData.encounter_id,
                      //   totalData,
                      //   totalData?.ticketid
                      //  )
                      handleEdited(totalData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action ===
                        process.env.REACT_APP_REFERRAL_UPDATETREATMENTPLAN
                    ) {
                      redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_EMR
                    ) {
                      redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_PRINT_FORM
                    ) {
                      printForm(totalData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_PRINT_PATIENT_FORM
                    ) {
                      referralPrint(totalData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_REFERRAL_QUEUE &&
                      l.action === process.env.REACT_APP_REFERRAL_SCHEDULE
                    ) {
                      handleMultiBookData(props?.PatientData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                      l.action === process.env.REACT_APP_DIALYSIS_CHECK_IN
                    ) {
                      redirectVisitRegsitration(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        l,
                        queue,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                      l.action === process.env.REACT_APP_DIALYSIS_EMR
                    ) {
                      redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId,
                        totalData?.field2
                      );
                    } else if (
                      queue === process.env.REACT_APP_NEPHROLOGIST_QUEUE &&
                      l.action === process.env.REACT_APP_NEPHROLOGIST_EMR
                    ) {
                      redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_PIC_REVIEW_QUEUE &&
                      l.action === process.env.REACT_APP_PIC_REVIEW_EMR
                    ) {
                      redirectTreatmentPlan(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_DIALYSIS_QUEUE &&
                      l.action === process.env.REACT_APP_DIALYSIS_BILLING
                    ) {
                      redirectBilling(
                        totalData?.personid,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.appointmentid,
                        totalData?.inputdoc?.encounterid,
                        totalData?.inputdoc?.resource_type,
                        totalData?.inputdoc?.patientid
                          ? totalData?.inputdoc?.patientid
                          : totalData?.inputdoc?.patientId,
                        totalData?.inputdoc?.encounterid,
                        totalData,
                        totalData?.ticketId,
                        l.action,
                        queueId
                      );
                    } else if (
                      queue === process.env.REACT_APP_LABORDER_QUEUE &&
                      l.action ===
                        process.env.REACT_APP_LABORDER_SPECIMENTCOLLECTED
                    ) {
                      props?.sampleFormOpen(totalData);
                    } else if (
                      queue === process.env.REACT_APP_NURSING_PROCEDURE_QUEUE &&
                      l.action === process.env.REACT_APP_NURSING_ADMINISTER
                    ) {
                      // nurseAdministration(totalData, queueId, l?.action);
                      props?.NursingProcedureOpen(totalData ,  l)
                      // nurseAdministration(
                      //   totalData,
                      //   queueId,
                      //   l?.action,
                      //   l.apiendpoint,
                      //   l.backEndCall
                      // );
                    } else if (
                      queue === process.env.REACT_APP_LABORDER_QUEUE &&
                      l.action ===
                        process.env
                          .REACT_APP_LABORDER_PRINT_ORDER_REQUISITION_FORM
                    ) {
                      printOrderRequestForm(totalData, queueId);
                    } else if (
                      queue === process.env.REACT_APP_REFUND_QUEUE &&
                      l.action === process.env.REACT_APP_REFUND_ACTION
                    ) {
                      redirectPaymentCollection(
                        totalData,
                        queueId
                      );
                    } 
                    else {
                      actionButtonClickEvent(
                        l.action,
                        l.apiendpoint,
                        l.backEndCall,
                        ticketId,
                        queue,
                        queueId
                      );
                    }
                  }
                  // props?.assignButtonAction
                  //   ? () => props.assignButtonAction()
                  //   : null
                }
                // name={l?.action}
              >
                {l?.action}
              </Button>
            );
          })}
        {/* {!assignReassignPractitioner && (
              )} */}
        {props.val?.status}
        
      </Div>
      <Divider id={`${parent_id}_hold_divider`} style={qdmstyles.divider} />
     
        <SideMenu
          id="patient_dashboard_cancel_sidemenu"
          open={state?.openDialog}
          direction="right"
          width={440}
          color="default"
          onClose={() => handlesidebarCancelbooking("closing")}
          style={{ padding: 0 }}
        >
          <CancelBookingPage
            parent_id={parent_id}
            cancelbookingreason={state?.cancelbookingreason}
            cacellationType={state?.cacellationType}
            appointmentId={props?.totalData?.inputdoc?.appointmentid}
            handleSelectCancelbooking={handleSelectCancelbooking}
            handlecahngeCancelbooking={handlecahngeCancelbooking}
            cancelData={state?.cancelData}
            allMasters={allMasters}
            noshow={noshow}
            setNoShow={setNoShow}
            advRejectOptions={totalData?.field5}
          />
        </SideMenu>
     
      <Popover
        id="high_priority_pop_over"
        style={{ zIndex: "10000000" }}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleHighAlertPopClose}
        disableRestoreFocus
      >
        <HighAlertPopUp
          handleHighAlertPopClose={handleHighAlertPopClose}
          fullDataQueve={totalData}
          // priorityTotalData={key: totalData}
          priorityTotalData={priorityTotalData}
          handlePriority={handlePriority}
        />
      </Popover>
      {state?.openUpload ? (
        <SideMenu
          id="patient_dashboard_cancel_sidemenu"
          open={state?.openUpload}
          direction="right"
          width={440}
          color="default"
          onClose={() => handlesidebarCancelbooking("uploadClose")}
          style={{ padding: 0 }}
        >
          <UploadFiles
            id={`${parent_id}_topnavbar_upload_uploadfiles`}
            uploadDocument={uploadDocument}
            files={files}
            uploadImage={uploadImage}
            uploadData={state?.uploadData}
            parent_id={parent_id}
          />
        </SideMenu>
      ) : null}
      {/* <Upload
                handleChange={uploadFun}
                label="Upload Documents"
                labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                className={"up-btn"}
                showIcon={true}
            />
         */}
      <MultiBookComp
        multiBookOpen={multiBookOpen}
        setMultiBookOpen={handleMultiBookData}
        mBData={mBData}
        selectedQueueid={selectedQueueid}
      />
    </div>
  );
};
export default withStyles(materialStyles)(withAllContexts(Queue));