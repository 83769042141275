import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/auth-slice";
import personSlice from "./slice/person-slice";
import searhcApiSlice from "./slice/search-slice";
import OrganizationRegisterPatientApiSlice from "./slice/organization-slice";
import docterListingReducer from "./slice/doctorlisting-slice";
import searchHospitalSlice from "./slice/search-hospital-slice";
import searchapiSlice from "./slice/searchapi-slice";
import visitRegisterPatientApiSlice from "./slice/visitregistration-slice";
import registrationSlice from "./slice/registration-slice";
import patientSlice from "./slice/patient-slice";
import appointmentSlice from "./slice/appointment-slice";
import visitRegistrationSlice from "./slice/visit-registration-slice";
import slotApiSlice from "./slice/slot-slice";
import patientDetailsMastersSlice from "./slice/patientdetails-slice";
import LabOrderApiSlice from "./slice/laborder-slice";
import RadiologySlice from "./slice/radiologyorder-slice";
import TreatmentSlice from "./slice/treatmentorder-slice";
import MedicationMastersSlice from "./slice/medication/masters-slice";
import DialysisApiSlice from "./slice/dialysis-slice";
import medicationsSlice from "./slice/medication/medication-slice";
import signOffSlice from "./slice/signoff-slice";
import DiagnosisMasterSlice from "./slice/diagnosis-slice";
import BillingDetailApiSlice from "./slice/billing-slice";
import chiefCompilenceMastersSlice from "./slice/cheifcomplaints-slice";
import DoccSlice from "./slice/docc/docs-slice";
import DashboardSlice from "./slice/dashboard-slice";
import CommonSlice from "./slice/common-slice";
import PatientDashboardSlice from "./slice/patientDashboard-slice";
import PayerChargeCodeMappingSlice from "./slice/payerChargeCodeMapping-slice";
import AdvancePaymentSlice from "./slice/advancePayment-slice";
import Finance_slice from "./slice/finance-slice";
import ReferalRegOutApiSlice from "./slice/ReferalRegOut-slice";
import emrBannerSlice from "./slice/emrBanner-slice";
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    person: personSlice.reducer,
    search: searhcApiSlice.reducer,
    organization: OrganizationRegisterPatientApiSlice.reducer,
    doctorlisting: docterListingReducer.reducer,
    searchHospital: searchHospitalSlice.reducer,
    searchapiSlice: searchapiSlice.reducer,
    visitregistration: visitRegisterPatientApiSlice.reducer,
    registration: registrationSlice.reducer,
    patient: patientSlice.reducer,
    appointment: appointmentSlice.reducer,
    slot: slotApiSlice.reducer,
    patientDetails: patientDetailsMastersSlice.reducer,
    LabOrderApiSlice: LabOrderApiSlice.reducer,
    RadiologySlice: RadiologySlice.reducer,
    TreatmentSlice: TreatmentSlice.reducer,
    MedicationMastersSlice: MedicationMastersSlice.reducer,
    dialysis: DialysisApiSlice.reducer,
    medicationsSlice: medicationsSlice.reducer,
    signOffSlice: signOffSlice.reducer,
    DiagnosisMasterSlice: DiagnosisMasterSlice.reducer,
    billing: BillingDetailApiSlice.reducer,
    chiefCompilenceMastersSlice: chiefCompilenceMastersSlice.reducer,
    DoccSlice: DoccSlice.reducer,
    DashboardSlice: DashboardSlice.reducer,
    CommonSlice: CommonSlice.reducer,
    PatientDashboard: PatientDashboardSlice.reducer,
    PayerChargeCodeMapping: PayerChargeCodeMappingSlice.reducer,
    AdvancePaymentSlice: AdvancePaymentSlice.reducer,
    FinanceSlice:Finance_slice.reducer,
    referalRegistrationOut:ReferalRegOutApiSlice.reducer,
    emrBannerData:emrBannerSlice.reducer,
  },
});

export default store;

// import { configureStore } from "@reduxjs/toolkit";

// import authSlice from "./slice/auth-slice";
// import personSlice from "./slice/person-slice";

// const store = configureStore({
//   reducer: {
//     auth: authSlice.reducer,
//     person: personSlice.reducer,
//   },
// });

// export default store;
