import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import { Col, Row, Text } from "qdm-component-library";
import React, { useEffect,useContext, useState } from "react";
import { UIColor } from "../themes/theme";
import { useLocation, useSearchParams } from "react-router-dom";
import actions from "../redux/actions";
import { useDispatch } from "react-redux";
import { AlertProps, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg, getTenantId, isObjectEmpty } from "../utils";
import jwtDecode from "jwt-decode";
import { AlertContext } from "../context";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
    width:"200vh"
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
};
function CurrentMedication(props) {
  const templocation = useLocation();
  const [searchParams] = useSearchParams();
  const [location,setLocation] = useState({})
  const dispatch = useDispatch();
  const { setSnack } = useContext(AlertContext);
  
  const [currentMedicationValue, setCurrentMedicationValue] = React.useState("");
  const [isEditMedic, setisEditMedic] = React.useState(false);
  const [notes, setNotes] = React.useState([]);

  const SaveCNLoading = useSelector(
    (state) => state?.patientDetails?.save_clinicalnotes?.loading
  );

  const getCurrentMedicationRead = async () =>{
    let current_medication = await dispatch(
      actions.GET_CURRENT_MEDICATION_ORDER(
        location?.state?.patientId
      )
    );
    current_medication = current_medication?.payload?.data;
    if (current_medication?.length > 0) {
      current_medication.forEach((v, i) => {
        if (v?.clicnicaldoctype === process.env.REACT_APP_CURRENT_MEDICATION) {
          setCurrentMedicationValue(v?.clicnicaldocdesc);
        }
      });
    }
    setNotes(current_medication);
  }
  const handlecurrentmedicationval = (value) => {
    setCurrentMedicationValue(value);
  };
  const currentcancel = () => {
    setisEditMedic(false);
    setCurrentMedicationValue(notes?.[0]?.clicnicaldocdesc);
  };
  const handleSave = async () => {
    const now = Date.now();
    const epochTimeInSeconds =  props?.patientgetData?.encounterStart ?  props?.patientgetData?.encounterStart : Math.floor(now / 1000);
    let payloadList = [];
    if (currentMedicationValue?.length > 0) {
      const payload = {
        _id: notes?.[0]?._id ?? "",
        tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
        facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
        patientid: location?.state?.patientId,
        encounterid: props?.patientgetData?.encounterId || props?.Encounterdata?.encounterID,
        clicnicaldocdate: epochTimeInSeconds,
        clicnicaldoctype: process.env.REACT_APP_CURRENT_MEDICATION ?? "",
        clicnicaldocdesc: currentMedicationValue,
        practitionerid: jwtDecode(localStorage.getItem("UserInfo"))
          ?.practitionerid,
      };
      payloadList.push(payload);
      if (payloadList.length > 0) {
        let currentclinicalupsert = await dispatch(
          actions.SAVE_CLINICAL_NOTES(
            payloadList
          )
        );
        currentclinicalupsert = currentclinicalupsert?.payload?.data
        if (!currentclinicalupsert?.error) {
          getCurrentMedicationRead()
          setisEditMedic(false)
          setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: isEditMedic
            ? " Current Medication Updated successfully!"
            : " Current Medication Saved successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Current Medication Save Failed",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      }
    } else {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the details",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const oneditClick = () => {
    setisEditMedic(true);
  };


  React.useEffect(() => {
    if(!(isObjectEmpty(location?.state || {}))){
      getCurrentMedicationRead();
    }
  }, [location?.state?.patientId]);
  React.useEffect(()=>{
    if(searchParams.get("data")){
      setLocation({state:JSON.parse(decodeURIComponent(searchParams.get("data")))})
    }
    else{
      setLocation(templocation)
    }
  },[])
  return (
    <Grid item lg={12}>
      <Row inLineStyles={styles.vitailsCard}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={styles.vitalsHead}
        >
          <Text
            inLineStyles={{
              fontWeight: 600,
              fontSize: "14px",
              color: UIColor.primaryColor.color,
              margin: "10px 5px 10px 5px",
            }}
          >
            {"Current Medication"}
          </Text>
          <Grid>
            {isEditMedic && (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={currentcancel}
              >
                Cancel
              </Button>
            )}
            {notes?.length > 0 ? (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={isEditMedic ? handleSave : oneditClick}
                disabled={SaveCNLoading}
              >
                {isEditMedic ? SaveCNLoading ? "Updating..." : "Update" : "Edit"}
                {SaveCNLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </Button>
            ) : (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={handleSave}
                disabled={SaveCNLoading}
              >
               {SaveCNLoading ? "Saving..." : "Save"}
               {SaveCNLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </Button>
            )}
          </Grid>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={{ padding: "30px" }}
        >
          <Grid item lg={12}>
            <TextareaAutosize
              onChange={(e) => handlecurrentmedicationval(e.target.value)}
              maxRows={4}
              placeholder="Enter text here"
              style={{
                width: "100%",
                height: "80px",
                outline: "1px solid rgb(222, 229, 236) ",
                border: "0px",
                borderRadius: "8px",
                padding: "5px",
              }}
              disabled={notes?.length > 0 && !isEditMedic ? true : false}
              value={currentMedicationValue}
            />
          </Grid>
        </Col>
      </Row>
    </Grid>
  );
}

export default CurrentMedication;
