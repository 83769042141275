import { dbName, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg } from "../../../utils";
import moment from "moment";
//import jwtDecode from "jwt-decode";

export const generateJSON = (dataList, oltype) => {
  //let token = jwtDecode(localStorage.getItem("UserInfo"));
  let payload = dataList.map((data) => {
    let extraJson = {};
    let filter = {};
    if (data.editId) {
      extraJson = {
        _id: data.editId,
      };

      filter = { filter: { _id: data.editId } };
    }
    return {
      db_name: dbName,
      entity: "CA_OrderLine",
      is_metadata: true,
      ...filter,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      doc: {
        ...extraJson,
        // facilityid: "",
        // tenantid: "12345",
        // Oltype: "LAB",
        // org_Id: "",
        // patient_Id: "Patient/11098",
        // encountertype: "CodingMaster/10164",
        // encounter_Id: "Encounter/12311",
        // order_Id: "",
        // orderSetCode: "",
        // orderCatalog: "OrderCatalog/10430",
        // OrderType: "CodingMaster/11014",
        // OrderNature: "CodingMaster/10621",
        // orderMode: "CodingMaster/10301",
        // priority: "Urgent",
        // orderDateTime: 1696876200,
        // occuranceStartDate: 1699953767,
        // occuranceEndDate: 0,
        // orderQty: 1,
        // orderUOM: "",
        // status: "CodingMaster/10191",
        // intent: "",
        // workflowStatus: "",
        // billQty: 1,
        // orderPrivilegeLevel: 0,
        // apptDate: 0,
        // apptRefNum: "",
        // Requestor: [
        //   {
        //     organization: "",
        //     practRole: "CodingMaster/10845",
        //     practId: "Practitioner/10303",
        //   },
        // ],
        // reasonCode: "CodingMaster/10061",
        // diagnosis: [],
        // indications: "",
        // consentReqd: false,
        // freqValue: 0,
        // freqValueUnit: "",
        // freqRate: 0,
        // freqRateUnit: "",
        // duration: 0,
        // durationType: "",
        // parentOrderId: "",
        // orderGroupRefId: "",
        // specimenRelated: [
        //   {
        //     reqd: true,
        //     volume: 0,
        //     container: "",
        //     FastingPeriod: 0,
        //   },
        // ],
        // performer: [],
        // notes: "",
        // patientInstruction: [{}],
        // accessionNum: "",
        // recurrent: [
        //   {
        //     every_date: 3,
        //     every_days: "CodingMaster/11058",
        //     starts_on: 1700019630,
        //     end_on_occurance_date: 0,
        //     end_on_occurance: 5,
        //     reoccurance_id: "",
        //   },
        // ],
        // preparatoryInstruction: [{}],
        // departmentalInstruction: [{}],
        // attribute: {
        //   view: "",
        //   bedSide: false,
        //   contrast: "",
        // },
        // recurrentList: [],
        // statusreason: "",

        //id: 12,
        facilityid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}), //token?.facilityid,
        tenantid: "1234", //token?.tenantid,
        Oltype: oltype,
        //"LAB",
        // "orgEntityType": 0,
        org_Id: data?.Organization_id ?? "",
        patient_Id: data?.patient_id ?? "",
        encountertype: data?.encountertype ?? "",
        encounter_Id: data?.encounter_Id ?? "",
        order_Id: "",
        orderSetCode: "",
        orderCatalog: data?.orderCatalog ?? "",
        orderCategory: data?.orderSetCode ?? "",
        OrderType: data?.OrderType ?? "",
        OrderNature: data?.OrderNature ?? "",
        orderMode: data?.orderMode ?? "",
        priority: data?.priority ?? "",
        orderDateTime: data?.orderDateTime ?? 0,
        occuranceStartDate: data?.occuranceStartDate ?? 0,
        occuranceEndDate: data?.occuranceEndDate ?? 0,
        orderQty: data?.orderQty ?? 0,
        orderUOM: "",
        status: data?.status ?? "",
        intent: "",
        workflowStatus: "",
        billQty: data?.orderQty ?? 0,
        orderPrivilegeLevel: 0,
        apptDate: 0,
        apptRefNum: "",
        Requestor: [...data?.Requestor] ?? [],
        reasonCode: data?.reasonCode ?? "",
        diagnosis: [...data?.diagnosis] ?? [],
        indications: data?.indications ?? "",
        consentReqd: false,
        freqValue: 0,
        freqValueUnit: "",
        freqRate: 0,
        freqRateUnit: "",
        duration: 0,
        durationType: "",
        parentOrderId: data?.parentOrderId ? data?.parentOrderId : "",
        orderGroupRefId: "",
        specimenRelated:
          [
            {
              reqd: data.specimenRelated[0].reqd,
              type: data.specimenRelated[0].type,
              volume: parseInt(data.specimenRelated[0].volume),
              volumeUOM: data.specimenRelated[0].volumeUOM,
              collectionMethod: data.specimenRelated[0].collectionMethod,
              container: data.specimenRelated[0].container,
              bodySite: data.specimenRelated[0].bodySite,
              FastingPeriod: parseInt(data.specimenRelated[0].FastingPeriod),
              FastingPeriodUOM: data.specimenRelated[0].FastingPeriodUOM,
            },
          ] ?? [],
        performer: [...data?.performer] ?? [],
        notes: data?.notes ?? "",
        patientInstruction: [...(data?.patientInstruction || [])],
        accessionNum: data?.accessionNum ?? "",
        recurrent:
          [
            {
              every_date: data?.every_date ?? 0,
              every_days: data?.every_days ?? "",
              starts_on: data?.starts_on ?? 0,
              //starts_on_days: data?.starts_on_days,
              //startes_on_occurance_date: data?.startes_on_occurance_date,
              //startes_on_occurance: data?.startes_on_occurance,
              end_on_occurance_date: data?.startes_on_occurance_date ?? 0,
              end_on_occurance: data?.startes_on_occurance ?? 0,
              reoccurance_id: "",
              preferredDays: data?.preferredDays,
              preferredWeeks: data?.preferredWeeks,
            },
          ] ?? [],
        preparatoryInstruction: [...(data?.preparatoryInstruction || [])], //--
        departmentalInstruction: [...(data?.departmentalInstruction || [])], //--
        attribute: {
          view: data?.attribute?.view ?? "",
          bedSide: data?.attribute?.bedSide ?? false,
          contrast: data?.attribute?.contrast ?? "",
        },
        recurrentList: [...data?.recurrentList] ?? [],
        statushistory: [...(data.statushistory || [])],
        statusreason: data?.statusreason ?? "",
      },
    };
  });

  return payload;
};

export const generateOrderJSON = (dataList, info) => {
  let payload = [];
  dataList.forEach((data) => {
      let RadiologyData = data.order.map((val) => {
        return makeRadiologyData(val, info);
      });
      payload = [...payload, ...RadiologyData];
  });
  return payload;
};


const makeRadiologyData = (order, info) => {
  return {
    db_name: dbName,
    entity: "CA_OrderLine",
    is_metadata: true,
    metadataId: process.env.REACT_APP_METADATAID,
    metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
    doc: {
      facilityid: info?.facilityid,
      tenantid: "1234",
      Oltype: order?.ordercategory?.ordercategory,
      org_Id: info?.org_id,
      patient_Id:info?.patient_Id,
      encountertype: info?.encounterType,
      encounter_Id: info?.encounter_Id,
      order_Id: "",
      orderSetCode: "",
      orderCatalog: order?.orderCatalog_id ?? "",
      OrderType: order?.orderType?._id ?? "",
      OrderNature: "",
      orderMode: "",
      priority: "",
      orderDateTime:moment().startOf("date").unix(),
      occuranceStartDate:moment().startOf("date").unix(),
      occuranceEndDate: 0,
      orderQty: 1,
      orderUOM: "",
      status: "",
      intent: "",
      workflowStatus: "",
      billQty: 1,
      orderPrivilegeLevel: 0,
      apptDate: 0,
      apptRefNum: "",
      Requestor: [
          {
              organization: "",
              practRole: "",
              practId: ""
          }
      ],
      reasonCode: "",
      diagnosis: [],
      indications: "",
      consentReqd: false,
      freqValue: 0,
      freqValueUnit: "",
      freqRate: 0,
      freqRateUnit: "",
      duration: 0,
      durationType: "",
      parentOrderId: "",
      orderGroupRefId: "",
      specimenRelated: [
          {
              reqd: true,
              volume: 0,
              container: "",
              FastingPeriod: 0
          }
      ],
      performer: [],
      notes: "",
      patientInstruction: [
          {}
      ],
      accessionNum: "",
      recurrent: [
          {
              every_date: 0,
              every_days: "",
              starts_on: 0,
              end_on_occurance_date: 0,
              end_on_occurance: 0,
              reoccurance_id: ""
          }
      ],
      preparatoryInstruction: [
          {}
      ],
      departmentalInstruction: [
          {}
      ],
      attribute: {
          view: "",
          bedSide: false,
          contrast: ""
      },
      recurrentList: [],
      statusreason: ""
    },
  };
};

export const ccGenerateJSON = (dataList)=>{

  console.log(dataList,"datalist")
  let payload = dataList?.map((val,i)=>{
    let extraJson = {};
    let filter = {};
    if (val.editId) {
      extraJson = {
        _id: val.editId,
      };
 
      filter = { filter: { _id: val.editId } };
    }
    return {
      db_name: dbName,
      entity: "CA_ChiefComplaints",
      is_metadata: true,
      ...filter,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      doc: {
            ...extraJson,
            recordeddate: val?.recordeddate ?? 0,
            tenantid: "1234", //token?.tenantid,
            facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}), //token?.facilityid,
            practitioner_id: val?.practitioner_id ?? "",
            practitionerrole_id: val?.practitionerrole_id ?? "practrole/1111",//val?.practitionerrole_id ?? "",
            identifier: val?.identifier ?? [],
            clinicalStatus: val?.clinicalStatus ?? "",
            verificationstatus: val?.verificationstatus ?? "",
            category: val?.category ?? "",
            severity: val?.severity ?? "",
            code: val?.code,
            bodysite: val?.bodysite,
            patient_id: val?.patient_id,
            encounter_id: val?.encounter_id,
            onset: [
                {
                    onsetdatetime: val?.onsetdatetime ?? 0,
                    onsetAge:  val?.onsetAge ?? "",
                    onsetperiod:  val?.onsetperiod ?? "",
                    onsetrange: val?.onsetrange ?? "",
                    onsetstring: val?.onsetstring ?? ""
                }
            ],
            abatement: [
                {
                    abatementdatetime: val?.abatementdatetime ?? 0,
                    abatementage: val?.abatementage ?? "",
                    abatementperiod: val?.abatementperiod ?? "",
                    abatementrange: val?.abatementrange ?? "",
                    abatementstring: val?.abatementstring ?? ""
                }
            ],
            relatedperson_id: val?.relatedperson_id ?? "",
            PersonID: val?.clinicalstatushistory[0]?.PersonID ?? val?.PersonID ?? "",
            note: val?.note ?? "",
            assertedby: val?.assertedby??"",
            patient_complaint: val?.patient_complaint ?? "",
            modifying_factor: val?.modifying_factor ?? ""
 
      }
    }
  });
  return payload;
}
