import React from "react";
import TabPanel from "./queueTabList/tabPanel";
import QueueTabpanel from "./queueTabpanel";
import QueueSelector from "./queueTabList/queueSelector";
import {
  Grid,
  Typography,
  useTheme,
  Paper,
  Tabs,
  Tab,
  Skeleton,
} from "@mui/material";
import { UIColor } from "../../../../themes/theme";
function setTabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WorkFlowComponent({
  queueList = [],
  selectQueue = "",
  HandleQueueSelect = () => null,
  queueColor = "", //FIXME -
  showTabs = true,
  tabsColor = "", //FIXME
  tabList = [],
  data = [],
  loadCustomCard = () => <></>,
  CardBtnClick = () => null,
  PopupAlert = () => null, //FIXME -
  filterClick = () => null,
  dataName = "", //FIXME
  colValue = "",
  avatarType = "",
  itemPerPage = 0,
  isgetQueueListData = false,
  isSelQueueDatas = false,
  selectedQueue = "",
  tabValue = 0,
  handleTabChange = () => null,
  handleRefundInfo=() => null,
  handleRefundCloseModal=() => null,
  refundDetailsOpen=false,
  refundValue = "",
  RefreshLatestQueue = () => {},
}) {
  const theme = useTheme();

  return (
    <div>
      <QueueSelector
        queueList={queueList}
        selectedQueue={selectedQueue}
        queueColor={queueColor}
        isgetQueueListData={isgetQueueListData}
        HandleQueueSelect={HandleQueueSelect}
      />
{isgetQueueListData ? (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 5,
    }}
  >
    {/* <CircularProgress style={{ color: "#EC6A49" }} size={20} /> */}
    <Skeleton variant="rounded" width={1650} height={40} />
  </div>
) : showTabs ? (
  <Paper sx={{ margin: "10px 20px 0 20px", marginTop: "10px" }} elevation={0}>
    <Tabs
      variant={tabList?.length > 6 ? "scrollable" : "fullWidth"}
      centered
      value={tabValue}
      onChange={handleTabChange}
      aria-label="simple tabs example"
      scrollButtons="auto"
      sx={{
        "& .MuiTab-root": {
          minWidth: 110, 
          maxWidth: 'none', 
        },
        "& .MuiTab-root.Mui-selected": {
          backgroundColor: theme.palette.common.white,
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#fff", 
        },
      }}
    >
      {tabList?.map((f, i) => (
        <Tab
          label={
            <Grid container alignItems="center" justifyContent="center" sx={{ display: 'flex', flexDirection: 'row', overflow: 'hidden'  }}>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  color={
                    tabValue === i
                      ? theme.palette.secondary.main
                      : theme.palette.common.black
                  }
                  variant="body2"
                  fontSize="12px"
                  fontWeight="bold" 
                  padding="0 10px" 
                  whiteSpace="nowrap" 
                  overflow="hidden" 
                  textOverflow="ellipsis" 
                >
                  {f?.value}
                </Typography>
              </Grid>
              {f?.data ?
                (<Grid item>
                <Typography
                  sx={{
                      backgroundColor: tabValue === i ? theme.palette.secondary.main : "#b6b6b6",
                      clipPath: 'circle(50%)',
                      width: '25px',
                      height: '25px',
                      textAlign: 'center',
                      lineHeight: '25px',
                  }}
                  color={theme.palette.common.white}
                  variant="body2"
                  fontSize="10px"
                >
                  {f?.data?.length}
                </Typography>
              </Grid>
              ) : (<></>)
            }
            </Grid>
          }
          {...setTabProps(i)}
          style={{
            backgroundColor: tabValue === i ? tabsColor || "#ffece8" : "#fafafa",
            borderRadius: "4px",
            border: tabValue === i ? "" : "0.5px solid #f0f0f0",
          }}
        />
      ))}
    </Tabs>
  </Paper>
) : null}
      {tabList?.length > 0 &&
                  tabList?.map((f, i) => {
        return (
          <TabPanel value={tabValue} index={i}>
            <QueueTabpanel
              data={showTabs ? data?.filter((d) => d.id === f.id) : data}
              tab_id={f.id}
              isMultiple={f.isMultiple}
              isDuplicateCheck={f.isDuplicateCheck}
              CardBtnClick={CardBtnClick}
              PopupAlert={PopupAlert}
              filterClick={filterClick}
              dataName={dataName}
              loadCustomCard={loadCustomCard}
              colValue={colValue}
              avatarType={avatarType}
              itemPerPage={itemPerPage}
              queueColor={queueColor}
              selectedQueue={selectedQueue}
              isSelQueueDatas={isSelQueueDatas}
              handleRefundInfo={handleRefundInfo}
              handleRefundCloseModal={handleRefundCloseModal}
              refundDetailsOpen={refundDetailsOpen}
              refundValue={refundValue}
              RefreshLatestQueue={RefreshLatestQueue}
            />
          </TabPanel>
        );
      })}
    </div>
  );
}

export default WorkFlowComponent;