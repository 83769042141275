import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Paper,
  Grid,
  Typography,
  Avatar,
  Chip,
  Button,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  LinearProgress,
  Box,
  Dialog,
  IconButton,
  useTheme,
  Badge,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OpacityIcon from "@mui/icons-material/Opacity";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoneIcon from "@mui/icons-material/Done";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MasksIcon from "@mui/icons-material/Masks";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import CoronavirusOutlinedIcon from "@mui/icons-material/CoronavirusOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CloseIcon from "@mui/icons-material/Close";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import RemoveIcon from "@mui/icons-material/Remove";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LocalHotelOutlinedIcon from '@mui/icons-material/LocalHotelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import HDDialogBox from "../../../../patientDetail/TreatmentProcedure";
import { epochToDDMonthYY } from "../../../../../utils";
const NewCard = ({ patient = {}, actionButtonClick = () => null, ...props }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [treatmentProcedureDialogOpen, setTreatmentProcedureDialogOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   console.log(patient,props, "props@@@");
   const getEpochTIme = (date) => {
    const unixMilliSeconds = date * 1000;
    const myDate = new Date(unixMilliSeconds);
    return myDate.toLocaleString();
  };
  return (
    <Paper variant="outlined">
      <Grid container p={1} gap={0.5}>
        {/* 1st row - bedno session date& time */}
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            {/* <Chip
              label={patient?.field8}
              variant="outlined"
              color="secondary"
              style={{ borderRadius: 2 }}
              size="small"
              sx={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
            /> */}
            {patient?.field8 ? (
              <Button size="small" color="primary">
                <LocalHotelOutlinedIcon fontSize="8px" color="secondary" />
                <Typography
                  variant="overline"
                  fontSize={"12px"}
                  color={"secondary"}
                  ml={0.5}
                >
                  {patient?.field8}
                </Typography>
              </Button>
            ) : (
              <></>
            )}
            {patient?.queueMetaData?.states?.states ? (
              <Button size="small" color="primary">
                <FiberManualRecordIcon
                  color="secondary"
                  style={{ height: "8px", width: "8px" }}
                />
                <Typography
                  variant="overline"
                  fontSize={"12px"}
                  color={"secondary"}
                  ml={0.5}
                >
                  {patient?.queueMetaData?.states?.states}
                </Typography>
              </Button>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item>
            <IconButton size="small">
              <Badge variant="dot" color="secondary" overlap="circular">
                <NotificationsNoneOutlinedIcon fontSize="10px" />
              </Badge>
            </IconButton>
            <IconButton
              // onClick={() => {
              //   setDialogOpen(!dialogOpen);
              // }}
              size="small"
            >
              <OpenWithIcon fontSize="10px" />
            </IconButton>
          </Grid>
        </Grid>
        {/*  2nd row - patient details */}
        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item container gap={2} xs={8}>
            <Grid item>
              <Avatar
                src={patient?.field9}
                variant="rounded"
                sx={{
                  height: "70px",
                  width: "60px",
                  backgroundColor: "#E5E5E5",
                }}
              >
                {patient?.field9
                  ? null
                  : patient?.field1?.slice(0, 1).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item direction={"column"}>
              <Grid item>
                <Typography variant="body2" fontSize={"14px"} sx={{display:"flex",alignItems:"center"}}>
                  <span style={{
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '120px'
                  }}>{patient?.field1}</span> <span>&nbsp;&nbsp; {patient?.field3?.slice(0, 3)}{" "}
                  &nbsp; {patient?.field4?.slice(0, 1)}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={"caption"} fontSize={"9px"}>
                  {patient?.field2 ? patient?.field2 : ""}
                  {patient?.field6 ? (
                    <>&nbsp; | &nbsp; {patient?.field6}</>
                  ) : (
                    ""
                  )}
                  {patient?.field11 ? (
                    <>&nbsp; | &nbsp; {patient?.field11}</>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" fontSize={"10px"}>
                  {patient?.date ? getEpochTIme(patient?.date) : ""}{" "}
                  {patient?.field12 ? <> | {patient?.field12}</> : ""}
                </Typography>
              </Grid> 
           
              <Grid item>
                <Typography variant={"caption"} fontSize={"8px"}>
                  {patient?.field14 ? patient?.field14 : ""}
                  {patient?.field15 ? (
                    <>&nbsp; | &nbsp; {patient?.field15}</>
                  ) : (
                    ""
                  )}
                  {patient?.field19 && patient?.field15 ? (
                    <>
                      &nbsp; | &nbsp;
                      <FiberManualRecordIcon
                        color="warning"
                        style={{ height: "8px", width: "8px" }}
                      />
                      RM {patient?.field19.toFixed(2)}
                    </>
                  ) : patient?.field19 ? (
                    <>
                      {" "}
                      <FiberManualRecordIcon
                        color="warning"
                        style={{ height: "8px", width: "8px" }}
                      />{" "}
                      RM {patient?.field19.toFixed(2)}
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
            </Grid>

          </Grid>
          <Grid item container xs={4} justifyContent={"flex-end"} >
              <Button variant="contained" startIcon={<MonitorHeartIcon  />} size="small" style={{backgroundColor:"#179e8e"}}  onClick={() => {
                setTreatmentProcedureDialogOpen(!treatmentProcedureDialogOpen);
              }} >
                    Hemodialysis
              </Button>
          </Grid>
        </Grid>
        {/* 4th row - progress bar */}
        {/* <Grid item container>
          <ToggleButtonGroup color="primary" size="small" fullWidth>
            <ToggleButton selected="true">
              <AssignmentIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton selected="true">
              <OpacityIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton selected="true">
              <HourglassEmptyIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton color="success">
              <DoneIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton>
              <WatchLaterIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton>
              <AccessTimeIcon fontSize="10px" />
            </ToggleButton>
            <ToggleButton>
              <TimelapseIcon fontSize="10px" />
            </ToggleButton>
          </ToggleButtonGroup>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="caption" fontSize={"10px"}>
                {patient?.field16 ? patient?.field16 : ""}{" "}
                {patient?.field12 ? <> | {patient?.field12}</> : ""}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography variant="caption" fontSize={"10px"}>
                Time elapsed - ⏱️ 20 mins
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
        {/* 5th row - actions */}

        <Grid item container justifyContent={"space-between"}>
          {patient?.action?.map((a, i) => (
            <Grid item>
              <Button
                variant={
                  "outlined"
                }
                size="small"
                onClick={() =>
                  actionButtonClick(patient?.ticketId, a, patient)
                }
              >
                <Typography variant="caption" fontSize={"10px"}>
                  {a?.action}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* popup dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <Grid container p={2} gap={2}>
          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              <Chip
                label="BED-007"
                variant="contained"
                color="primary"
                style={{ borderRadius: 4 }}
                sx={{
                  fontSize: "12px",
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setDialogOpen(!dialogOpen);
                }}
                size="small"
              >
                <RemoveIcon fontSize="10px" />
              </IconButton>
            </Grid>
          </Grid>
          {/*  2nd row - patient details */}
          <Grid
            item
            xs={12}
            p={2}
            container
            justifyContent={"space-between"}
            bgcolor={"#F1F6FC"}
            borderRadius={1}
          >
            <Grid item xs={10} container gap={2}>
              <Grid item>
                <Avatar
                  src={patient?.field9}
                  variant="rounded"
                  sx={{
                    height: "70px",
                    width: "60px",
                    backgroundColor: "#E5E5E5",
                  }}
                >
                  {patient?.field9
                    ? null
                    : patient?.field1?.slice(0, 1).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item direction={"column"}>
                <Grid item>
                  <Typography variant="body2" fontWeight={"600"}>
                    <span>{patient?.field1}</span> <span>&nbsp;&nbsp; {patient?.field3?.slice(0, 3)}{" "}
                    &nbsp; {patient?.field4?.slice(0, 1)}</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"caption"} fontSize={"10px"}>
                    {patient?.field2 ? patient?.field2 : ""}
                    {patient?.field6 ? (
                      <>&nbsp; | &nbsp; {patient?.field6}</>
                    ) : (
                      ""
                    )}
                    {patient?.field11 ? (
                      <>&nbsp; | &nbsp; {patient?.field11}</>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={"caption"} fontSize={"10px"}>
                    Domestric Corporate | General
                  </Typography>
                </Grid>
                <Grid item container gap={2} mt={1}>
                  <Grid item>
                    <Chip
                      size="small"
                      label="Outstanding : RM 798.78"
                      variant="outlined"
                      icon={
                        <MoneyOffIcon
                          size="small"
                          fontSize="8"
                          color="warning"
                        />
                      }
                      color="warning"
                      style={{
                        borderRadius: 4,
                        fontSize: "10px",
                        backgroundColor: "#FCF3E4",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      size="small"
                      label="Wallet : RM 200"
                      variant="outlined"
                      icon={
                        <AccountBalanceWalletOutlinedIcon
                          size="small"
                          fontSize="2"
                          color="success"
                        />
                      }
                      color="success"
                      style={{
                        borderRadius: 4,
                        fontSize: "10px",
                        backgroundColor: "#E6EFE7",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container direction={"column"} gap={0.5}>
                <Grid item>
                  <Chip
                    size="small"
                    label="No Show"
                    variant="outlined"
                    icon={<VisibilityOffIcon fontSize="8px" color="warning" />}
                    color="warning"
                    style={{
                      borderRadius: 4,
                      fontSize: "10px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 3rd row - Alert bar */}
          <Grid item container>
            <Alert
              variant="outlined"
              icon={<ErrorOutlineOutlinedIcon fontSize="inherit" />}
              style={{
                width: "100%",
                padding: "0 0 0 10px",
                alignItems: "center",
                fontSize: "11px",
              }}
              color="warning"
            >
              Due for Intradialysis 2nd hour documentation
            </Alert>
          </Grid>
          {/* 4th row - Tab details (Allergy, Diagnosis, Vitals) */}
          <Grid item container>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    sx={{
                      "& .MuiButtonBase-root": {
                        minHeight: 0,
                        height: 0,
                      },
                      "& .MuiTabs-scroller": {
                        height: "30px",
                      },
                      "& .MuiTabs-root": {
                        minHeight: 0,
                      },
                      minHeight: "30px",
                    }}
                    allowScrollButtonsMobile
                  >
                    <Tab
                      icon={<MasksIcon fontSize="8" />}
                      iconPosition="start"
                      label={
                        <Typography variant="caption" fontSize={"10px"}>
                          Allergy
                        </Typography>
                      }
                      value="1"
                    />
                    <Tab
                      icon={<CoronavirusOutlinedIcon fontSize="8" />}
                      iconPosition="start"
                      label={
                        <Typography variant="caption" fontSize={"10px"}>
                          Diagnosis
                        </Typography>
                      }
                      value="2"
                    />
                    <Tab
                      icon={<MonitorHeartOutlinedIcon fontSize="8" />}
                      iconPosition="start"
                      label={
                        <Typography variant="caption" fontSize={"10px"}>
                          Vitals
                        </Typography>
                      }
                      value="3"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Typography variant="caption" fontSize={"10px"}>
                    {" "}
                    Display all the captured Allergies details -Allergy, Type,
                    severity and verification.
                  </Typography>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <Typography variant="caption" fontSize={"10px"}>
                    Display all the captured Diagnosis details - Diagnosis, Use
                    and Verfication.
                  </Typography>
                </TabPanel>
                <TabPanel value="3">
                  <Typography variant="caption" fontSize={"10px"}>
                    {" "}
                    height : 5ft,weight: 10kg, BMI: 10,...
                  </Typography>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
          {/* 5th row - toggle buttons */}
          <Grid item container>
            <ToggleButtonGroup color="primary" size="small">
              <ToggleButton selected="true">
                <AssignmentIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  PreDialysis
                </Typography>
              </ToggleButton>
              <ToggleButton selected="true">
                <OpacityIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  Baseline
                </Typography>
              </ToggleButton>
              <ToggleButton selected="true">
                <HourglassEmptyIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  1 st Hour
                </Typography>
              </ToggleButton>
              <ToggleButton color="success">
                <DoneIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  2 nd Hour
                </Typography>
              </ToggleButton>
              <ToggleButton>
                <WatchLaterIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  3 rd Hour
                </Typography>
              </ToggleButton>
              <ToggleButton>
                <AccessTimeIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  4 th Hour
                </Typography>
              </ToggleButton>
              <ToggleButton>
                <TimelapseIcon fontSize="10px" />
                <Typography variant="caption" fontSize={"8px"} mx={0.5}>
                  PostDialysis
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* 6th row - progress bar */}
          <Grid item container>
            <Box sx={{ width: "100%", height: "3px" }}>
              <LinearProgress variant="determinate" value={40} />
            </Box>
          </Grid>
          {/* 7th row - Action buttons */}
          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              <Button variant="outlined" size="small">
                <Typography variant="caption" fontSize={"12px"}>
                  Stop
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" size="small">
                <Typography variant="caption" fontSize={"12px"}>
                  Pause
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" size="small">
                <Typography variant="caption" fontSize={"12px"}>
                  EMR
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {/* treatment prodecure dialog */}
      <Dialog
        open={treatmentProcedureDialogOpen}
        onClose={() => setTreatmentProcedureDialogOpen(false)}
        fullWidth={"70%"}
        maxWidth={"70%"}
        style={{ zIndex: 2 }}
        PaperProps={{
          sx: {
            //backgroundColor: theme.palette.primary.light, // Set the background color here
            color: "black",
            // Optional: set text color
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6">HD TREATMENT</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setTreatmentProcedureDialogOpen(false)}
            size="large"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <HDDialogBox patientData={patient} setTreatmentProcedureDialogOpen={setTreatmentProcedureDialogOpen} />
        </DialogContent>
      </Dialog>
    </Paper>
    //   <>
    //   {patient?.data.map((item, index) => (
    //     <Paper key={index} variant="outlined" sx={{ mb: 2 }}>
    //       <Grid container p={1} gap={0.5}>
    //         {/* 1st row - bedno session date& time */}
    //         <Grid item container justifyContent="space-between">
    //           <Grid item>
    //             {item?.field8 && (
    //               <Button size="small" color="primary">
    //                 <LocalHotelOutlinedIcon
    //                   fontSize="small"
    //                   color="secondary"
    //                 />
    //                 <Typography variant="overline" fontSize="8px" ml={0.5}>
    //                   {item.field8}
    //                 </Typography>
    //               </Button>
    //             )}
    //             {item?.queueMetaData?.states?.states && (
    //               <Button size="small" color="primary">
    //                 <FiberManualRecordIcon
    //                   fontSize="small"
    //                   color="secondary"
    //                   style={{ height: "8px", width: "8px" }}
    //                 />
    //                 <Typography variant="overline" fontSize="8px" ml={0.5}>
    //                   {item.queueMetaData.states.states}
    //                 </Typography>
    //               </Button>
    //             )}
    //           </Grid>
    //           <Grid item>
    //             <IconButton size="small">
    //               <Badge variant="dot" color="secondary" overlap="circular">
    //                 <NotificationsNoneOutlinedIcon fontSize="small" />
    //               </Badge>
    //             </IconButton>
    //             <IconButton
    //               size="small"
    //               onClick={() => setDialogOpen(!dialogOpen)}
    //             >
    //               <OpenWithIcon fontSize="small" />
    //             </IconButton>
    //           </Grid>
    //         </Grid>
    //         {/*  2nd row - patient details */}
    //         <Grid item container justifyContent="space-between">
    //           <Grid item container gap={2}>
    //             <Grid item>
    //               <Avatar
    //                 src={item.field9}
    //                 variant="rounded"
    //                 sx={{
    //                   height: "70px",
    //                   width: "60px",
    //                   backgroundColor: "#E5E5E5",
    //                 }}
    //               >
    //                 {!item.field9 && item.field1?.slice(0, 1).toUpperCase()}
    //               </Avatar>
    //             </Grid>
    //             <Grid item direction="column">
    //               <Grid item>
    //                 <Typography variant="body2" fontSize="14px">
    //                   {item.field1} &nbsp;&nbsp; {item.field3?.slice(0, 3)}{" "}
    //                   &nbsp; {item.field4?.slice(0, 1)}
    //                 </Typography>
    //               </Grid>
    //               <Grid item>
    //                 <Typography variant="caption" fontSize="9px">
    //                   {item.field2 && `${item.field2} | `}
    //                   {item.field6 && `${item.field6} | `}
    //                   {item.field11 && `${item.field11}`}
    //                 </Typography>
    //               </Grid>
    //               <Grid item>
    //                 <Typography variant="caption" fontSize="8px">
    //                   {item.field14 && `${item.field14} | `}
    //                   {item.field15 && `${item.field15} | `}
    //                   {item.field19 && (
    //                     <>
    //                       <FiberManualRecordIcon
    //                         color="warning"
    //                         style={{ height: "8px", width: "8px" }}
    //                       />
    //                       RM {item.field19.toFixed(2)}
    //                     </>
    //                   )}
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //         {/* 4th row - progress bar */}
    //         <Grid item container>
    //           <ToggleButtonGroup color="primary" size="small" fullWidth>
    //             <ToggleButton>
    //               <AssignmentIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton>
    //               <OpacityIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton>
    //               <HourglassEmptyIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton color="success">
    //               <DoneIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton>
    //               <WatchLaterIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton>
    //               <AccessTimeIcon fontSize="small" />
    //             </ToggleButton>
    //             <ToggleButton>
    //               <TimelapseIcon fontSize="small" />
    //             </ToggleButton>
    //           </ToggleButtonGroup>
    //           <Grid container justifyContent="space-between">
    //             <Grid item>
    //               <Typography variant="caption" fontSize="10px">
    //                 {item.field16 && `${item.field16} | `}
    //                 {item.field12 && `${item.field12}`}
    //               </Typography>
    //             </Grid>
    //             <Grid item>
    //               <Typography variant="caption" fontSize="10px">
    //                 Time elapsed - ⏱️ 20 mins
    //               </Typography>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //         {/* 5th row - actions */}
    //         <Grid item container justifyContent="space-between">
    //           {patient?.action?.map((action, idx) => (
    //             <Grid item key={idx}>
    //               <Button
    //                 variant={
    //                   item.action.length === idx + 1
    //                     ? "contained"
    //                     : "outlined"
    //                 }
    //                 size="small"
    //                 onClick={() =>
    //                   props.actionButtonClick(item.field1, action.action, props)
    //                 }
    //               >
    //                 <Typography variant="caption" fontSize="8px">
    //                   {action.action}
    //                 </Typography>
    //               </Button>
    //             </Grid>
    //           ))}
    //         </Grid>
    //       </Grid>
    //       {/* popup dialog */}
    //       <Dialog
    //         open={dialogOpen}
    //         onClose={() => setDialogOpen(false)}
    //         fullWidth
    //         maxWidth="sm"
    //       >
    //         {/* Dialog content */}
    //         <Grid container p={2} gap={2}>
    //           <Grid item container justifyContent="space-between">
    //             <Grid item>
    //               <Chip
    //                 label="BED-007"
    //                 variant="contained"
    //                 color="primary"
    //                 style={{ borderRadius: 4 }}
    //                 sx={{ fontSize: "12px" }}
    //               />
    //             </Grid>
    //             <Grid item>
    //               <IconButton
    //                 onClick={() => setDialogOpen(!dialogOpen)}
    //                 size="small"
    //               >
    //                 <RemoveIcon fontSize="small" />
    //               </IconButton>
    //             </Grid>
    //           </Grid>
    //           {/*  2nd row - patient details */}
    //           <Grid
    //             item
    //             xs={12}
    //             p={2}
    //             container
    //             justifyContent="space-between"
    //             bgcolor="#F1F6FC"
    //             borderRadius={1}
    //           >
    //             <Grid item xs={10} container gap={2}>
    //               <Grid item>
    //                 <Avatar
    //                   src="https://images.pexels.com/photos/769772/pexels-photo-769772.jpeg"
    //                   variant="rounded"
    //                   sx={{
    //                     height: "100px",
    //                     width: "80px",
    //                   }}
    //                 />
    //               </Grid>
    //               <Grid item direction="column">
    //                 <Grid item>
    //                   <Typography variant="body2" fontWeight="600">
    //                     Mr.Jeremy Renner &nbsp;&nbsp; 34 M
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item>
    //                   <Typography variant="caption" fontSize="10px">
    //                     MRN_001 | AP-1234 | ENC-1234
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item>
    //                   <Typography variant="caption" fontSize="10px">
    //                     Domestric Corporate | General
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item container gap={2} mt={1}>
    //                   <Grid item>
    //                     <Chip
    //                       size="small"
    //                       label="Outstanding : RM 798.78"
    //                       variant="outlined"
    //                       icon={
    //                         <MoneyOffIcon
    //                           size="small"
    //                           fontSize="8"
    //                           color="warning"
    //                         />
    //                       }
    //                       color="warning"
    //                       style={{
    //                         borderRadius: 4,
    //                         fontSize: "10px",
    //                         backgroundColor: "#FCF3E4",
    //                       }}
    //                     />
    //                   </Grid>
    //                   <Grid item>
    //                     <Chip
    //                       size="small"
    //                       label="Wallet : RM 200"
    //                       variant="outlined"
    //                       icon={
    //                         <AccountBalanceWalletOutlinedIcon
    //                           size="small"
    //                           fontSize="2"
    //                           color="success"
    //                         />
    //                       }
    //                       color="success"
    //                       style={{
    //                         borderRadius: 4,
    //                         fontSize: "10px",
    //                         backgroundColor: "#E6EFE7",
    //                       }}
    //                     />
    //                   </Grid>
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //             <Grid item xs={2}>
    //               <Grid container direction="column" gap={0.5}>
    //                 <Grid item>
    //                   <Chip
    //                     size="small"
    //                     label="No Show"
    //                     variant="outlined"
    //                     icon={
    //                       <VisibilityOffIcon fontSize="8px" color="warning" />
    //                     }
    //                     color="warning"
    //                     style={{
    //                       borderRadius: 4,
    //                       fontSize: "10px",
    //                     }}
    //                   />
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //           </Grid>

    //           {/* 3rd row - Alert bar */}
    //           <Grid item container>
    //             <Alert
    //               variant="outlined"
    //               icon={<ErrorOutlineOutlinedIcon fontSize="inherit" />}
    //               style={{
    //                 width: "100%",
    //                 padding: "0 0 0 10px",
    //                 alignItems: "center",
    //                 fontSize: "11px",
    //               }}
    //               color="warning"
    //             >
    //               Due for Intradialysis 2nd hour documentation
    //             </Alert>
    //           </Grid>
    //           {/* 4th row - Tab details (Allergy, Diagnosis, Vitals) */}
    //           <Grid item container>
    //             <Box sx={{ width: "100%", typography: "body1" }}>
    //               <TabContext value={value}>
    //                 <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
    //                   <TabList
    //                     onChange={handleChange}
    //                     sx={{
    //                       "& .MuiButtonBase-root": {
    //                         minHeight: 0,
    //                         height: 0,
    //                       },
    //                       "& .MuiTabs-scroller": {
    //                         height: "30px",
    //                       },
    //                       "& .MuiTabs-root": {
    //                         minHeight: 0,
    //                       },
    //                       minHeight: "30px",
    //                     }}
    //                     allowScrollButtonsMobile
    //                   >
    //                     <Tab
    //                       icon={<MasksIcon fontSize="8" />}
    //                       iconPosition="start"
    //                       label={
    //                         <Typography variant="caption" fontSize="10px">
    //                           Allergy
    //                         </Typography>
    //                       }
    //                       value="1"
    //                     />
    //                     <Tab
    //                       icon={<CoronavirusOutlinedIcon fontSize="8" />}
    //                       iconPosition="start"
    //                       label={
    //                         <Typography variant="caption" fontSize="10px">
    //                           Diagnosis
    //                         </Typography>
    //                       }
    //                       value="2"
    //                     />
    //                     <Tab
    //                       icon={<MonitorHeartOutlinedIcon fontSize="8" />}
    //                       iconPosition="start"
    //                       label={
    //                         <Typography variant="caption" fontSize="10px">
    //                           Vitals
    //                         </Typography>
    //                       }
    //                       value="3"
    //                     />
    //                   </TabList>
    //                 </Box>
    //                 <TabPanel value="1">
    //                   <Typography variant="caption" fontSize="10px">
    //                     Display all the captured Allergies details - Allergy,
    //                     Type, severity and verification.
    //                   </Typography>
    //                 </TabPanel>
    //                 <TabPanel value="2">
    //                   {" "}
    //                   <Typography variant="caption" fontSize="10px">
    //                     Display all the captured Diagnosis details - Diagnosis,
    //                     Use and Verfication.
    //                   </Typography>
    //                 </TabPanel>
    //                 <TabPanel value="3">
    //                   <Typography variant="caption" fontSize="10px">
    //                     {" "}
    //                     height : 5ft,weight: 10kg, BMI: 10,...
    //                   </Typography>
    //                 </TabPanel>
    //               </TabContext>
    //             </Box>
    //           </Grid>
    //           {/* 5th row - toggle buttons */}
    //           <Grid item container>
    //             <ToggleButtonGroup color="primary" size="small">
    //               <ToggleButton selected="true">
    //                 <AssignmentIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   PreDialysis
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton selected="true">
    //                 <OpacityIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   Baseline
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton selected="true">
    //                 <HourglassEmptyIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   1 st Hour
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton color="success">
    //                 <DoneIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   2 nd Hour
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton>
    //                 <WatchLaterIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   3 rd Hour
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton>
    //                 <AccessTimeIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   4 th Hour
    //                 </Typography>
    //               </ToggleButton>
    //               <ToggleButton>
    //                 <TimelapseIcon fontSize="10px" />
    //                 <Typography variant="caption" fontSize="8px" mx={0.5}>
    //                   PostDialysis
    //                 </Typography>
    //               </ToggleButton>
    //             </ToggleButtonGroup>
    //           </Grid>
    //           {/* 6th row - progress bar */}
    //           <Grid item container>
    //             <Box sx={{ width: "100%", height: "3px" }}>
    //               <LinearProgress variant="determinate" value={40} />
    //             </Box>
    //           </Grid>
    //           {/* 7th row - Action buttons */}
    //           <Grid item container justifyContent="space-between">
    //             <Grid item>
    //               <Button variant="outlined" size="small">
    //                 <Typography variant="caption" fontSize="12px">
    //                   Stop
    //                 </Typography>
    //               </Button>
    //             </Grid>
    //             <Grid item>
    //               <Button variant="outlined" size="small">
    //                 <Typography variant="caption" fontSize="12px">
    //                   Pause
    //                 </Typography>
    //               </Button>
    //             </Grid>
    //             <Grid item>
    //               <Button variant="contained" size="small">
    //                 <Typography variant="caption" fontSize="12px">
    //                   EMR
    //                 </Typography>
    //               </Button>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Dialog>
    //     </Paper>
    //   ))}
    // </>
  );
};
export default NewCard;
