import { Chip } from "@material-ui/core";
import jwtDecode from "jwt-decode";
import { Div } from "qdm-component-library";
import React from "react";
import { useSelector } from "react-redux";

function Wallet({ walletData = "", walletClick = () => null }) {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const Outstanding = useSelector((state) => state?.PatientDashboard?.outstanding);
  return (
    <React.Fragment>
      <Div>
        {Outstanding ? (
          <Chip
            variant="outlined"
            size="small"
            label={`Wallet : ${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode} ${Outstanding?.data?.[0]?.advanceamount}`}
            onClick={walletClick}
            style={{
              borderRadius: 4,
              color: "#3C41A0", // Or '#9BADD8' for blue hue variation
              borderColor: "#3C41A0", // Or '#9BADD8' for blue hue variation
              backgroundColor: "#eaf5ff",
              width: "170px",
            }}
          />
        ) : null}
      </Div>
    </React.Fragment>
  );
}

export default Wallet;
