import * as React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField, Typography, Button, MenuItem, Autocomplete, Grid } from '@mui/material';
import axios from 'axios';
import { AlertProps, dbName, urls } from '../../utils';
import { AlertContext } from '../../context';

const bpSchema = Yup.string()
  // .required('Blood pressure is required')
  .test('valid-bp', 'Invalid BP format. Ensure format is 90/120 and values are between 40 and 250', value => {
    if (!value) return true;
    value = value.replace(/[^0-9/]/g, '');
    const numberOfSlashes = (value.match(/\//g) || []).length;
    if (numberOfSlashes !== 1) return false;
    const parts = value.split('/');
    if (parts.length !== 2) return false;
    const [value1, value2] = parts.map(Number);
    const isValidRange = (val) => val >= 40 && val <= 250;
    return isValidRange(value1) && isValidRange(value2);
  });

  const timePeriodSchema = Yup.object().shape({
    Time: Yup.string().nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    BloodPressure: bpSchema.nullable(),
    Pulse: Yup.number().min(50, 'Pulse should be between 50 and 250')
      .max(250, 'Pulse should be between 50 and 250').nullable(true).transform((value, originalValue) => originalValue === '' ? null : value),
    Heparin: Yup.mixed().nullable(),
    Qb: Yup.number().min(150, 'Qb should be between 150 and 500')
      .max(500, 'Qb should be between 150 and 500').nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    VenousPressure: Yup.number().nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    Temperature: Yup.number().min(0, 'Temperature should be between 0 and 100')
      .max(100, 'Temperature should be between 0 and 100').nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    Qd: Yup.number().min(150, 'Qd should be between 150 and 500')
      .max(500, 'Qd should be between 150 and 500').nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    Conductivity: Yup.number().min(12, 'Conductivity should be between 12 and 15')
      .max(15, 'Conductivity should be between 12 and 15').nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    AccumulatedUFV: Yup.number().min(0, 'Accumulated UFV should be between 0 and 2000')
      .max(2000, 'Accumulated UFV should be between 0 and 2000').nullable().transform((value, originalValue) => originalValue === '' ? null : value),
    NursingNotes: Yup.mixed().nullable(),
    StaffID: Yup.mixed().nullable(),
    COMPLICATION: Yup.array().of(
      Yup.object().shape({
        complication: Yup.string().nullable(),
        action: Yup.string().nullable(),
        time: Yup.string().nullable(),
      })
    ).nullable(),
    TransmembranePressure: Yup.number().nullable().transform((value, originalValue) => originalValue === '' ? null : value),
  });

const schema = Yup.object().shape({
  baseline: timePeriodSchema,
  firstHour: timePeriodSchema.optional(),
  secondHour: timePeriodSchema.optional(),
  thirdHour: timePeriodSchema.optional(),
});


export default function IntraDialysisTable(props) {
  const alertContext = React.useContext(AlertContext);
  const { control, handleSubmit, formState: { errors,isDirty, dirtyFields, touchedFields },reset,setValue,register,watch } = useForm({
    resolver: yupResolver(schema),
    mode:"onChange",
    defaultValues: {
      baseline: {
          Time: "",
          BloodPressure: "",
          Pulse: null,
          Heparin: null,
          Qb: null,
          AnterialPressure: null,
          VenousPressure: null,
          Temperature: null,
          Qd: null,
          Conductivity: null,
          AccumulatedUFV: null,
          NursingNotes: "",
          StaffID: "",
          TransmembranePressure: null,
          COMPLICATION: [{ complication: '', action: '', time: '' }],
      },
      firstHour: {
        Time: "",
        BloodPressure: "",
        Pulse: null,
        Heparin: null,
        Qb: null,
        AnterialPressure: null,
        VenousPressure: null,
        Temperature: null,
        Qd: null,
        Conductivity: null,
        AccumulatedUFV: null,
        NursingNotes: "",
        StaffID: "",
        TransmembranePressure: null,
        COMPLICATION: [{ complication: '', action: '', time: '' }],
      },
      secondHour: {
        Time: "",
        BloodPressure: "",
        Pulse: null,
        Heparin: null,
        Qb: null,
        AnterialPressure: null,
        VenousPressure: null,
        Temperature: null,
        Qd: null,
        Conductivity: null,
        AccumulatedUFV: null,
        NursingNotes: "",
        StaffID: "",
        TransmembranePressure: null,
        COMPLICATION: [{ complication: '', action: '', time: '' }],
      },
      thirdHour: {
        Time: "",
        BloodPressure: "",
        Pulse: null,
        Heparin: null,
        Qb: null,
        AnterialPressure: null,
        VenousPressure: null,
        Temperature: null,
        Qd: null,
        Conductivity: null,
        AccumulatedUFV: null,
        NursingNotes: "",
        StaffID: "",
        TransmembranePressure: null,
        COMPLICATION: [{ complication: '', action: '', time: '' }],
      },
    },
  });

  // const [open, setopen] = React.useState(true);
  const [DropDownVal, setDropDownVal] = React.useState([]);
  const watchdata = watch();
  const onSubmit = (data) => {
    console.log(data,isDirty, dirtyFields, touchedFields,props?.IntraDialysis,data,"()()(){}{}")
    let intraData = {}
    if(dirtyFields?.baseline){
      intraData.baseline = data?.baseline
    }
    if(dirtyFields?.firstHour){
      let finaldata = data?.firstHour
      finaldata = Object.assign({}, finaldata, finaldata.firstHour)
      delete finaldata.firstHour;
      intraData.firstHour = finaldata
    }
    if(dirtyFields?.secondHour){
      let finaldata = data?.secondHour
      finaldata = Object.assign({}, finaldata, finaldata.secondHour)
      delete finaldata.secondHour;
      intraData.secondHour = finaldata
    }
    if(dirtyFields?.thirdHour){
      let finaldata = data?.thirdHour
      finaldata = Object.assign({}, finaldata, finaldata.thirdHour)
      delete finaldata.thirdHour;
      intraData.thirdHour = finaldata
    }
    alertContext.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: `Intradialysis ${props?.IntraDialysis ? "Updated" : "Added"}`,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    console.log(intraData,"intraData")
    props?.setIntraDialysis({
      baseline: intraData?.baseline ?? props?.IntraDialysis?.baseline,
      firstHour: intraData?.firstHour ?? props?.IntraDialysis?.firstHour,
      secondHour: intraData?.secondHour ?? props?.IntraDialysis?.secondHour,
      thirdHour: intraData?.thirdHour ?? props?.IntraDialysis?.thirdHour,
    })
    props?.setisMasterSave(true)
    // props?.setisIntraDialysis(true)
    // setopen(true);
  };

  React.useEffect(() => {
    const getDropDownValues = async () => {
        const payload = {
          db_name: dbName,
          filter: {
            type: [
              "NURSINGNOTES",
              "HEPARINTYPE"
            ],
            tenantid: "1234",
            facilityid: "",
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urls?.readQdmQueries,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };
        axios.request(config)
        .then((response) => {
          const transformedData = response?.data?.reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key]; 
            return acc;
          }, {});
          setDropDownVal(transformedData)
        })
        .catch((error) => {
          console.log(error);
        });
      };
      getDropDownValues();
  }, []);

  console.log(errors,props?.IntraDialysis,"errorserrors")

  const {
    fields: baselineFields,
    append: appendBaseline,
    remove: removeBaseline,
  } = useFieldArray({ control, name: 'baseline.COMPLICATION' });

  const {
    fields: firstHourFields,
    append: appendFirstHour,
    remove: removeFirstHour,
  } = useFieldArray({ control, name: 'firstHour.COMPLICATION' });

  const {
    fields: secondHourFields,
    append: appendSecondHour,
    remove: removeSecondHour,
  } = useFieldArray({ control, name: 'secondHour.COMPLICATION' });

  const {
    fields: thirdHourFields,
    append: appendThirdHour,
    remove: removeThirdHour,
  } = useFieldArray({ control, name: 'thirdHour.COMPLICATION' });

  React.useEffect(() => {
    // if(props?.IntraDialysis){
    //   Object.keys(props?.IntraDialysis).forEach(key => {
    //     setValue(key, props?.IntraDialysis[key]);
    //   });
    // } else {
      // if(props?.defaultValues){
       

          // Object.keys(props?.IntraDialysis).forEach(key => {
            if(props?.IntraDialysis){
              console.log(props?.IntraDialysis,"{}{}{}((")
              if(Object?.keys(props?.IntraDialysis?.baseline ?? {}).length !== 0){
                setValue('baseline', props?.IntraDialysis['baseline']);
              } else {
                let BaseLine = {
                  baseline:{
                    Time: props?.defaultValues?.Complicationtime_baseline ? new Date(props?.defaultValues?.Complicationtime_baseline * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }): "",
                    Qb: props?.defaultValues?.qb ? parseInt(props?.defaultValues?.qb) : null,
                    Qd:props?.defaultValues?.qd_baseline ? parseInt(props?.defaultValues?.qd_baseline) : null ,
                    NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                    COMPLICATION:[{
                      complication: props?.defaultValues?.Complicationtext_baseline,
                      action:props?.defaultValues?.Complicationaction_baseline,
                      time: props?.defaultValues?.Complicationtime_baseline ? new Date(props?.defaultValues?.Complicationtime_baseline * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }): "",
                    }]
                  },
                }
                setValue('baseline', BaseLine);
              }
              if(Object?.keys(props?.IntraDialysis?.firstHour ?? {}).length !== 0){
                setValue('firstHour', props?.IntraDialysis['firstHour']);
              } else {
                let FirstHour = {
                  firstHour:props?.defaultValues?.Conductivity_firsthour ? {
                    Time: props?.defaultValues?.Complicationtime_firsthour ? new Date(props?.defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    : "",
                    Qb: props?.defaultValues?.qb,
                    Qd:props?.defaultValues?.qd_firsthour,
                    NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                    Conductivity:props?.defaultValues?.Conductivity_firsthour,
                    COMPLICATION:[{
                      complication: props?.defaultValues?.Complicationtext_firsthour,
                      action:props?.defaultValues?.Complicationaction_firsthour,
                      time: props?.defaultValues?.Complicationtime_firsthour ? new Date(props?.defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                       : "",
                    }]
                  } : {}
                }
                setValue('firstHour',FirstHour)
              }
              if(Object?.keys(props?.IntraDialysis?.secondHour ?? {}).length !== 0){
                setValue('secondHour', props?.IntraDialysis['secondHour']);
              } else {
                let SecondHour = {
                  secondHour:props?.defaultValues?.Conductivity_secondhour ? {
                    Time: props?.defaultValues?.Complicationtime_secondhour ? new Date(props?.defaultValues?.Complicationtime_secondhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    : "",
                    Qb: props?.defaultValues?.qb,
                    Qd:props?.defaultValues?.qd_secondhour,
                    NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                    Conductivity:props?.defaultValues?.Conductivity_secondhour,
                    COMPLICATION:[{
                      complication: props?.defaultValues?.Complicationtext_secondhour,
                      action:props?.defaultValues?.Complicationaction_secondhour,
                      time: props?.defaultValues?.Complicationtime_secondhour ? new Date(props?.defaultValues?.Complicationtime_secondhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                       : "",
                    }]
                  } : {},
                }
                setValue('secondHour',SecondHour?.secondHour)
              }
              if(Object?.keys(props?.IntraDialysis?.thirdHour ?? {}).length !== 0){
                console.log("()*")
                setValue('thirdHour', props?.IntraDialysis['thirdHour']);
              } else {
                let ThirdHour = {
                  thirdHour:props?.defaultValues?.Conductivity_thirdhour ? {
                    Time: props?.defaultValues?.Complicationtime_thirdhour ? new Date(props?.defaultValues?.Complicationtime_thirdhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    : "",
                    Qb: props?.defaultValues?.qb,
                    Qd:props?.defaultValues?.qd_thirdhour,
                    NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                    Conductivity:props?.defaultValues?.Conductivity_thirdhour,
                    COMPLICATION:[{
                      complication: props?.defaultValues?.Complicationtext_thirdhour,
                      action:props?.defaultValues?.Complicationaction_thirdhour,
                      time: props?.defaultValues?.Complicationtime_thirdhour ? new Date(props?.defaultValues?.Complicationtime_thirdhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                       : "",
                    }]
                  } : {}
                }
                console.log("()**",ThirdHour,props?.defaultValues)
                setValue('thirdHour',ThirdHour?.thirdHour)
              }
            } else {
              const defaultBaseline = {
                baseline:{ 
                  Time: props?.defaultValues?.Complicationtime_baseline ? new Date(props?.defaultValues?.Complicationtime_baseline * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }): "",
                  Qb: props?.defaultValues?.qb ? parseInt(props?.defaultValues?.qb) : null,
                  Qd:props?.defaultValues?.qd_baseline ? parseInt(props?.defaultValues?.qd_baseline) : null ,
                  NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                  COMPLICATION:[{
                    complication: props?.defaultValues?.Complicationtext_baseline,
                    action:props?.defaultValues?.Complicationaction_baseline,
                    time: props?.defaultValues?.Complicationtime_baseline ? new Date(props?.defaultValues?.Complicationtime_baseline * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }): "",
                  }]
                },
                firstHour:props?.defaultValues?.Conductivity_firsthour ? {
                  Time: props?.defaultValues?.Complicationtime_firsthour ? new Date(props?.defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                  : "",
                  Qb: props?.defaultValues?.qb,
                  Qd:props?.defaultValues?.qd_firsthour,
                  NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                  Conductivity:props?.defaultValues?.Conductivity_firsthour,
                  COMPLICATION:[{
                    complication: props?.defaultValues?.Complicationtext_firsthour,
                    action:props?.defaultValues?.Complicationaction_firsthour,
                    time: props?.defaultValues?.Complicationtime_firsthour ? new Date(props?.defaultValues?.Complicationtime_firsthour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    : "",
                  }]
                } : {},
                secondHour:props?.defaultValues?.Conductivity_secondhour ? {
                  Time: props?.defaultValues?.Complicationtime_secondhour ? new Date(props?.defaultValues?.Complicationtime_secondhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                  : "",
                  Qb: props?.defaultValues?.qb,
                  Qd:props?.defaultValues?.qd_secondhour,
                  NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                  Conductivity:props?.defaultValues?.Conductivity_secondhour,
                  COMPLICATION:[{
                    complication: props?.defaultValues?.Complicationtext_secondhour,
                    action:props?.defaultValues?.Complicationaction_secondhour,
                    time: props?.defaultValues?.Complicationtime_secondhour ? new Date(props?.defaultValues?.Complicationtime_secondhour * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    : "",
                  }]
                } : {},
                thirdHour:props?.defaultValues?.Conductivity_thirdhour ? {
                  Time: props?.defaultValues?.Complicationtime_thirdhour ? new Date(props?.defaultValues?.Complicationtime_thirdhour * 1000).toISOString().substr(11, 5)
                  : "",
                  Qb: props?.defaultValues?.qb,
                  Qd:props?.defaultValues?.qd_thirdhour,
                  NursingNotes:props?.defaultValues?.Nursingnotes?.value,
                  Conductivity:props?.defaultValues?.Conductivity_thirdhour,
                  COMPLICATION:[{
                    complication: props?.defaultValues?.Complicationtext_thirdhour,
                    action:props?.defaultValues?.Complicationaction_thirdhour,
                    time: props?.defaultValues?.Complicationtime_thirdhour ? new Date(props?.defaultValues?.Complicationtime_thirdhour * 1000).toISOString().substr(11, 5)
                    : "",
                  }]
                } : {}
              };
              Object.keys(defaultBaseline).forEach(key => {
                setValue(key, defaultBaseline[key]);
              });
            }
          // });
      // }
  },[props?.IntraDialysis,props?.defaultValues])


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TableContainer component={Paper} style={{ margin: 10, width: '99%' }}>
        <Table sx={{ minWidth: 500 }} style={{ borderCollapse: 'collapse' }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow sx={{ margin: 10, height: 20 }}>
              <TableCell>
                <Typography variant="button">Hourly Monitoring</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button">BaseLine</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button">1st Hour</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button">2nd Hour</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button">3rd Hour</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              'Time',
              'BloodPressure',
              'Pulse',
              'Heparin',
              'Qb',
              'VenousPressure',
              'Temperature',
              'Qd',
              'Conductivity',
              'AccumulatedUFV',
              'NursingNotes',
              'StaffID',
              'TransmembranePressure',
            ].map((fieldName) => (
              <TableRow key={fieldName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {fieldName}
                </TableCell>
                {['baseline', 'firstHour', 'secondHour', 'thirdHour'].map((timePeriod, colIndex) => (
                  <TableCell align="center" key={colIndex}>
                    <Controller
                      name={`${timePeriod}.${fieldName}`}
                      control={control}
                      render={({ field }) => (
                        <>
                          {fieldName === 'Heparin' || fieldName === 'NursingNotes' || fieldName === 'StaffID' ? (
                            <Autocomplete
                              {...field}
                              options={
                                fieldName === 'Heparin'
                                  ? Array.from({ length: 20 },(_, i) => (i + 1) * 500).map(String) || []
                                  : fieldName === 'NursingNotes'
                                  ? DropDownVal?.NURSINGNOTES?.Value || []
                                  : props?.striptestList || []
                              }
                              value={field.value || null}
                              getOptionLabel={(option) => option?.label || option?.display || option?.value || option?.name || option}
                              onChange={(_, data) => field.onChange(data?.label || data?.display || data?.value || data?.name || data)}
                              // disabled={open}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: 180 }}
                                  error={!!errors?.[timePeriod]?.[fieldName]}
                                  helperText={
                                    errors?.[timePeriod]?.[fieldName]?.type === "required" || errors?.[timePeriod]?.[fieldName]?.type === "optionality" ? "" : errors?.[timePeriod]?.[fieldName]?.message
                                  }
                                />
                              )}
                            />
                          ) : (
                            <TextField
                              {...field}
                              type={
                                fieldName === 'Time' ? 'time' :
                                fieldName === 'Pulse' ? 'number' : 'text'
                              }
                              variant="outlined"
                              size="small"
                              sx={{ width: 180 }}
                              // disabled={open}
                              error={!!errors?.[timePeriod]?.[fieldName]}
                              helperText={
                                errors?.[timePeriod]?.[fieldName]?.type === "required" || errors?.[timePeriod]?.[fieldName]?.type === "optionality" ? "" : errors?.[timePeriod]?.[fieldName]?.message
                              }
                            />
                          )}
                        </>
                      )}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row">COMPLICATION</TableCell>
              {/* {['baseline', 'firstHour', 'secondHour', 'thirdHour'].map((timePeriod, colIndex) => ( */}
                <TableCell align="center" key="1">
                  <Controller
                    name="baseline.COMPLICATION"
                    control={control}
                    render={() => (
                      baselineFields.map((item, index) => (
                        <div key={item.id}>
                          <TextField
                            {...register(`baseline.COMPLICATION.${index}.complication`)}
                            defaultValue={item.complication}
                            label="Complication"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.complication}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.complication?.message}
                          />
                          <TextField
                            {...register(`baseline.COMPLICATION.${index}.action`)}
                            defaultValue={item.action}
                            label="Action"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.action}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.action?.message}
                          />
                          <TextField
                            {...register(`baseline.COMPLICATION.${index}.time`)}
                            defaultValue={item.time}
                            type="time"
                            label="Time"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.time}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.time?.message}
                          />
                        </div>
                      ))
                    )}
                  />
                </TableCell>
                <TableCell align="center" key="2">
                  <Controller
                    name="firstHour.COMPLICATION"
                    control={control}
                    render={() => (
                      firstHourFields.map((item, index) => (
                        <div key={item.id}>
                          <TextField
                            {...register(`firstHour.COMPLICATION.${index}.complication`)}
                            defaultValue={item.complication}
                            label="Complication"
                            variant="outlined"
                            // disabled={open}
                            size="small"
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.complication}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.complication?.message}
                          />
                          <TextField
                            {...register(`firstHour.COMPLICATION.${index}.action`)}
                            defaultValue={item.action}
                            label="Action"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.action}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.action?.message}
                          />
                          <TextField
                            {...register(`firstHour.COMPLICATION.${index}.time`)}
                            defaultValue={item.time}
                            type="time"
                            label="Time"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.time}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.time?.message}
                          />
                        </div>
                      ))
                    )}
                  />
                </TableCell>
                <TableCell align="center" key="3">
                  <Controller
                    name="secondHour.COMPLICATION"
                    control={control}
                    render={() => (
                      secondHourFields.map((item, index) => (
                        <div key={item.id}>
                          <TextField
                            {...register(`secondHour.COMPLICATION.${index}.complication`)}
                            defaultValue={item.complication}
                            label="Complication"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.complication}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.complication?.message}
                          />
                          <TextField
                            {...register(`secondHour.COMPLICATION.${index}.action`)}
                            defaultValue={item.action}
                            label="Action"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{shrink:true}}
                            // disabled={open}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.action}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.action?.message}
                          />
                          <TextField
                            {...register(`secondHour.COMPLICATION.${index}.time`)}
                            defaultValue={item.time}
                            type="time"
                            label="Time"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.time}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.time?.message}
                          />
                        </div>
                      ))
                    )}
                  />
                </TableCell>
                <TableCell align="center" key="4">
                  <Controller
                    name="thirdHour.COMPLICATION"
                    control={control}
                    render={() => (
                      thirdHourFields.map((item, index) => (
                        <div key={item.id}>
                          <TextField
                            {...register(`thirdHour.COMPLICATION.${index}.complication`)}
                            defaultValue={item.complication}
                            label="Complication"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{shrink:true}}
                            // disabled={open}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.complication}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.complication?.message}
                          />
                          <TextField
                            {...register(`thirdHour.COMPLICATION.${index}.action`)}
                            defaultValue={item.action}
                            label="Action"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{shrink:true}}
                            // disabled={open}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.action}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.action?.message}
                          />
                          <TextField
                            {...register(`thirdHour.COMPLICATION.${index}.time`)}
                            defaultValue={item.time}
                            type="time"
                            label="Time"
                            variant="outlined"
                            size="small"
                            // disabled={open}
                            InputLabelProps={{shrink:true}}
                            sx={{ width: 180, marginBottom: 1 }}
                            error={!!errors?.baseline?.COMPLICATION?.[index]?.time}
                            helperText={errors?.baseline?.COMPLICATION?.[index]?.time?.message}
                          />
                        </div>
                      ))
                    )}
                  />
                </TableCell>

              {/* ))} */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {props?.treatmentPermission == "edit" ? (
        <Grid
            container
            justifyContent="flex-end"
            gap={2}
            style={{ marginRight: "10px" }}
          >
            {/* {open ? (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setopen(false);
                    // props?.setisMasterSave(false);
                  }}
                >
                  Open
                </Button>
              </Grid>
            ) : ( */}
              <>
                <Grid item>
                  <Button variant="outlined" onClick={() => {
                    reset();
                    }} 
                >
                    Clear
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button variant="outlined" onClick={() => setopen(true)} // disabled={open}>
                    Cancel
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button variant="outlined" type="submit">
                  {props?.IntraDialysis ? "Update" : "Add"}
                  </Button>
                </Grid>
              </>
            {/* )} */}
          </Grid>
      ): (<></>)}
    </form>
  );
}
