import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Div, Button, Row, Col } from "qdm-component-library";
import withAppBar from "../../hoc/withAppBar";
import actions from "../../redux/actions";
import { AppRoutes } from "../../router/routes";
import { AlertContext } from "../../context";
import withAllContexts from "../../hoc/withAllContexts";
import TopNavbar from "../../components/navBar/topNavBar";
import Loading from "../../components/global/loading";
import {
  AlertProps,
  checkError,
  fetchQueueData,
  makeQueueData,
  putOnHold,
  getUserInfo,
  uploadImage,
  fetchUserInfo,
  getUtcTime,
  utcTOLocal,
  readFileAsBase64,
  __tenantid__,
  actionButtonClick,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import {
  PatientListView,
  EncounterInfo,
  Pactitioner,
  DeviceDetails,
  RefNote,
  RefDetails,
  RefTo,
  RefFrom,
  RefLetter,
  BookingDetails,
  ReasonDetails,
  TopHeader,
  PatientStatusCard,
} from "./components";
import { patientVisit, patientVisitTitle } from "../../utils/routesList";
import { checkWithCasbin } from "../../utils/permissionHandling";
import jwtDecode from "jwt-decode";
import moment from "moment";
import styles from "./styles";
import axios from "axios";
//import PatientStatusCard from "../../components"
const VisitRegistration = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertContext = useContext(AlertContext);
  const getEncounterType = useSelector(
    (state) => state?.visitRegistration?.getEncounterType
  );
  const getEncounterClass = useSelector(
    (state) => state?.visitRegistration?.getEncounterClass
  );
  const [allMasters, setAllMasters] = useState([]);
  const [Persondata, setPersondata] = useState([]);
  const [appointmentData, setappointmentData] = useState([]);
  const [encounterData, setencounterData] = useState([]);
  const [choosemember, setchoosemember] = useState([]);
  const [reasonID, setreasonID] = useState(0);
  const [btnName, setbtnName] = useState(false);
  const [loading, setloading] = useState(true);
  const [activeId, setactiveId] = useState(1);
  const [Files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [loadDefaultData, setLoadDefaultData] = useState({});
  const [loadDefaultResourcetype, setLoadDefaultResourcetype] = useState({});
  const [loadDefaultLocationType, setLoadDefaultLocationType] = useState({});
  const [locationData, setLocationData] = useState({});
  const [encounterType, setEncounterType] = useState({});
  const [propsdata, setPropsdata] = useState({
    appointmentId: location?.state?.appointmentId
      ? location?.state?.appointmentId
      : "",
    Ispatient: location?.state?.Ispatient,
    isedit: location?.state?.isedit,
    isEdited: location?.state?.isedited,
    isRevise: location?.state?.isRevise,
    walkin: location?.state?.walkin,
    isBack: location?.state?.isBack,
  });
  const loadVisitRegisterDefaultValues = async () => {
    let payload = {
      screenid: process.env.REACT_APP_VISIT_REGISTRATION_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localstoragekeyname:"userinfo",returnobjkeyname:"tenantid"}),
      filtervalues: {
        appointmentid: propsdata?.appointmentId ? propsdata?.appointmentId : "",
      },
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (
        res?.data?.response?.screenId ===
        process.env.REACT_APP_VISIT_REGISTRATION_SCREEN_ID
      ) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = [
          "visit_reason",
          "type",
          "resource_type",
          "start_type",
          "class",
          "visit_type",
          "location_type",
          "consult_mode",
        ];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        setLoadDefaultData(stateValues);
        setLoadDefaultResourcetype({
          label: stateValues?.resource_type?._id,
          value: stateValues?.resource_type?.value,
          // _id: stateValues?.location_type?._id,
        });
        setLoadDefaultLocationType({
          label: stateValues?.location_type?._id,
          value: stateValues?.location_type?.value,
          // _id: stateValues?.location_type?._id,
        });
      }
    }).catch((error) => {
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };

  useEffect(() => {
    getAllMasters();
    getData();
    if (location?.state?.isFinanceBack) {
      setLocationData({
        encounter: {
          visit_type:location?.state?.visitData?.encounter?.visit_type,
          visit_reason:location?.state?.visitData?.encounter?.visit_reason,
          start_type:location?.state?.visitData?.encounter?.start_type,
          class:location?.state?.visitData?.encounter?.class,
          type: location?.state?.visitData?.encounter?.type,
          mode: location?.state?.visitData?.encounter?.mode,
        },
        practitioner: {
          clinic: {},
          role:  location?.state?.visitData?.practitioner?.role,
          Resourcetype:  location?.state?.visitData?.practitioner?.Resourcetype,
          speciality: location?.state?.visitData?.practitioner?.speciality,
          type:  location?.state?.visitData?.practitioner?.type,
          id_name: location?.state?.visitData?.practitioner?.id_name
        },
        device: {},
        helthcare: {
          name: location?.state?.visitData?.helthcare?.name
        },
        accompained: {},
      });
    }
  }, []);

  // get all master

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };
  // Appoinment Get
  const getData = async () => {
    if (location?.state?.isedit) {
    } else if (!location?.state?.isEdited) {
      await loadVisitRegisterDefaultValues();
    }

    await appointmentread();
    let data = await dispatch(actions.GET_ENCOUNTER_MASTER_TYPE());

    setEncounterType(data?.payload.data);
    if (!propsdata?.isedit && !location?.state?.isEdited) {
      await constructEncounterTypeObjectData(data);
    }

    // if (
    //   (props.queueManagement?.data || []).length === 0 &&
    //   (props.queueManagement?.previousData || []).length === 0 &&
    //   (props.queueManagement?.onHoldData || []).length === 0
    // ) {
    //   fetchQueueData(dispatch, props, alertContext);
    // } else {
    //   const userData = await fetchUserInfo();
    //   const permissionA = await checkWithCasbin(["queueSideNav"]);
    //   const permissionData = permissionA.write.find(
    //     (d) =>
    //       d === "appointmentQueueDataN" ||
    //       d === "appointmentQueueDataD" ||
    //       d === "appointmentQueueDataF"
    //   );
    //   const [, displayData, onHoldData, completedData] = makeQueueData(
    //     permissionData,
    //     props.queueManagement.allData,
    //     userData,
    //     false
    //   );
    //   if(propsdata?.isedit)
    //   {

    //   }
    //   props.queueManagement.setDisplayData(displayData);
    //   props.queueManagement.setOnHoldData(onHoldData);
    //   props.queueManagement.setPreviousData(completedData);
    // }
    setloading(false);
  };

  //on Submit

  const handleSubmit = async (UploadedFiles) => {
    const visitData = props?.visitData;
    //const patientStatus = await dispatch(actions.GET_ENCOUNTER_STATUS());
    let extraJson = {};

    let attachments = [];
    const promises = Files?.map((val) => readFileAsBase64(val));
    if (promises) {
      const base64Results = await Promise.all(promises);
      Files?.map((val, i) => {
        let obj = {
          fileName: val?.name,
          fileid: "",
          filetype: val?.type,
          base64string: base64Results[i],
        };
        attachments.push(obj);
      });
    }

    if (viewFiles === undefined) {
      attachments = attachments ? attachments : [];
    } else {
      attachments =
        viewFiles === undefined ? attachments : attachments.concat(viewFiles);
    }

    if (propsdata?.isBack) {
      extraJson = {
        _id: propsdata?.encounterDetails[0].properties.doc._id,
        id: propsdata?.encounterDetails[0].properties.doc.id,
      };
    }

    let jwtToken = localStorage.getItem("JwtToken");
    const info = localStorage.getItem("UserInfo");
    let infoss = jwtDecode(info);

    const data = [
      {
        doc: {
          encounter_id: appointmentData?.[0]?.encounterid?.[0]?._id
            ? appointmentData?.[0]?.encounterid?.[0]?._id
            : "",
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: infoss?.facilityid,
          privilage: encounterData?.privilage ?? "",
          encounter_no: encounterData?.encounter_no ?? "",
          patient_id: appointmentData?.[0]?.PersonID?.[0]?.Patient?.[0]?._id,
          type: visitData?.encounter?.type?._id
            ? visitData?.encounter?.type?._id
            : "",
          class: visitData?.encounter?.class?._id
            ? visitData?.encounter?.class?._id
            : "",
          start_type: visitData?.encounter?.start_type?._id
            ? visitData?.encounter?.start_type?._id
            : "",
          end_type: encounterData?.end_type ?? "",
          visit_type: visitData?.encounter?.visit_type?._id
            ? visitData?.encounter?.visit_type?._id
            : "",
          priority: encounterData?.priority ?? "",
          priorityreason: encounterData?.priorityreason ?? "",
          participants: [
            {
              resoucetype: visitData?.practitioner?.Resourcetype?.label
                ? visitData?.practitioner?.Resourcetype?.label
                : "",
              // ressubtype: visitData?.practitioner?.Resourcetype?.label
              //   ? visitData?.practitioner?.Resourcetype?.label
              //   : "",
              ressubtype: visitData?.practitioner?.type?.label
                ? visitData?.practitioner?.type?.label
                : "",
              resrole: visitData?.practitioner?.role?.label
                ? visitData?.practitioner?.role?.label
                : "",
              speciality: visitData?.practitioner?.speciality?.label
                ? visitData?.practitioner?.speciality?.label
                : "",
              resouceid: visitData?.practitioner?.id_name?.label
                ? visitData?.practitioner?.id_name?.label
                : "",
              isprimary: true,
              start: getUtcTime(),
              end: 0,
            },
          ],
          episodeofcare_id: encounterData?.episodeofcare_id ?? "",
          eventid: encounterData?.eventid ?? "",
          eventrefid: encounterData?.eventrefid ?? "",
          servicedtls: [
            {
              servicetype: visitData?.helthcare?.type?.id
                ? visitData?.helthcare?.type?.id
                : "",
              serviceid: visitData?.helthcare?.name?.label
                ? visitData?.helthcare?.name?.label
                : "",
              serviceqty: visitData?.helthcare?.category?.id
                ? visitData?.helthcare?.category?.id
                : "",
            },
          ],
          appointmentid: propsdata?.appointmentId
            ? propsdata?.appointmentId
            : "",
          encounterdate: location?.state?.isedit? encounterData?.encounterdate : getUtcTime(),
          encounterenddate: getUtcTime(moment().endOf("day")),
          plannedstartdate: encounterData?.plannedstartdate ?? 0,
          plannedenddate: encounterData?.plannedenddate ?? 0,

          reason: [
            {
              use: visitData?.encounter?.visit_reason?._id
                ? process.env.REACT_APP_ENCOUNTER_REASON
                : "",
              reasoncode: visitData?.encounter?.visit_reason?._id
                ? visitData?.encounter?.visit_reason?._id
                : "",
              reasondesc: "",
            },
            {
              use: visitData?.encounter?.Revist_Reason?._id
                ? process.env.REACT_APP_REVISIT_REASON
                : "",
              reasoncode: visitData?.encounter?.Revist_Reason?._id
                ? visitData?.encounter?.Revist_Reason?._id
                : "",
              reasondesc: "",
            },
          ],
          location: encounterData?.location ??  [
            {
              physicaltype: "",
              location_id: "",
              status: true,
              periodstart: 0,
              periodend: 0,
            },
          ],
          admission: encounterData?.admission ?? [
            {
              preadmissionidentifier: "",
              admitsource: "",
              readmission: "",
              dischargedisposition: "",
              dietpreference: "",
            },
          ],
          origintype: encounterData?.origintype ?? "",
          originlocationid: encounterData?.originlocationid ?? "",
          originorgtype: encounterData?.originorgtype ?? "",
          originorgid: encounterData?.originorgid ?? "",
          destinationtype: encounterData?.destinationtype ?? "",
          destinationlocationid: encounterData?.destinationlocationid ?? "",
          destinationorgtype: encounterData?.destinationorgtype ?? "",
          destinationorgid: encounterData?.destinationorgid ?? "",
          specialcourtesy: encounterData?.specialcourtesy ?? "",
          specialarrangement: encounterData?.specialarrangement ?? "",
          refencounterid: encounterData?.refencounterid ?? "",
          mode: visitData?.encounter?.mode,
          attachment: attachments,
          followupreqd: encounterData?.followupreqd ?? false,
          followupuom: encounterData?.followupuom ?? "",
          followupdur: encounterData?.followupdur ?? 3,
          status: encounterData?.status ?? "",
          statushistory: encounterData?.statushistory ?? [
            {
              eventid: "",
              frmstatus: "",
              tostatus: "",
              modifieddate: 0,
              remarks: "",
            },
          ],
          referenceid: encounterData?.referenceid ?? [
            {
              refid: "",
              refidtype: "",
              refsource: "",
            },
          ],
        },
      },
    ];
    if (propsdata?.isBack) {
      data[0].doc.id =
        props.location?.state?.encounterDetails?.[0]?.properties?.doc?.id;
    }

    const validate = Validate(data[0]?.doc);

    if (validate) {
      const res = await dispatch(
        actions.ORGANIZATION_VISIT_REGISTRATION({
          payloaddata: data[0],
          enId: propsdata?.isBack
            ? propsdata?.encounterDetails[0]?.properties.doc._id
            : null,
          encounterId: appointmentData?.encounter?.[0]
            ? appointmentData.encounter[0]?.slice(10, 15)
            : null,
          jwtToken,
        })
      );
      const status = checkError(props.oraganizationvisitRegister);
      if (status.isError) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: status.errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
      if (res?.payload?.data?.validation_error || res.payload?.data?.error) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg:
            res?.payload?.data?.validation_error?.[0]?.Errormsg ||
            res.payload?.data?.message ||
            "something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setbtnName(false);
        return;
      } else {
        if(!Boolean(location?.state?.isRevise)){
          await actionButtonClick(
            location?.state?.stateAction,
            location?.state?.stateEndPoint,
            location?.state?.stateBackEndCall,
            location?.state?.stateTicketid
          )
            .then((res) => {
              if (!res?.error) {
                alertContext?.setSnack({
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: "Your visit has been Checked-In",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
              } else {
                alertContext?.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: res?.Response?.[0]?.errorMessage,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
              }
            })  
            .catch((error) => {
              alertContext?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please contact to admin",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              // window.location.href = AppRoutes.dashboard;
            });
        }
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: data[0]?.doc?.encounter_id !== "" ? "Encounter Updated successfully" : "Encounter number generated successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        navigate(AppRoutes.financeRegistration, {
          state: {
            prevRoute: AppRoutes.patientVisitDetails,
            encounterDetails: res?.payload?.data,
            from: propsdata.from,
            visitData: JSON.parse(JSON.stringify(visitData)),
            patientId: appointmentData?.[0]?.PersonID?.[0]?.Patient?.[0]?._id,
            appointmentId: propsdata.appointmentId,
            Ispatient: propsdata.Ispatient,
            mobileNumber: "",
            stateAction: location?.state?.stateAction,
            stateEndPoint: location?.state?.stateEndPoint,
            stateBackEndCall: location?.state?.stateBackEndCall,
            stateTicketid: location?.state?.stateTicketid,
            isRevise: Boolean(location?.state?.isRevise),
            personId: location?.state?.personId,
            isVisit: !Boolean(location?.state?.isRevise),
            // appointmentData?.[0]?.PersonID?.[0]?.Patient?.[0]?.telecom[1]
            //   ?.value ||
            //propsdata?.patientData ||
            //propsdata?.mobileNo,
          },
        });
        setLocationData({});
        let patientData = props.visitData;
        patientData.practitioner["type"] = {
          value: "",
          label: "",
        };
        patientData.practitioner["id_name"] = {
          value: "",
          label: "",
        };
        patientData.practitioner["speciality"] = {
          value: "",
          label: "",
        };
        patientData.practitioner["role"] = {
          value: "",
          label: "",
        };
        patientData.setVisitDetails({
          ...patientData,
        });
      }
    } else {
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Fill All the Mandatory Fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setbtnName(false);
      return;
    }
  };

  // on submit validation
  const Validate = (doc) => {
    const visitform = props.visitData;
    let errorClass = false;
    let errorType = false;
    let errorStartType = false;
    let errorVisitType = false;
    let errorReason = false;
    let errorResourseType = false;
    let errorResoursesubtype = false;
    let errorResouceId = false;

    if (!doc?.class) {
      visitform.encounter["error"] = {
        class: true,
        ...visitform.encounter["error"],
      };
      errorClass = true;
    }

    if (!doc?.type) {
      visitform.encounter["error"] = {
        type: true,
        ...visitform.encounter["error"],
      };
      errorType = true;
    }
    if (!doc?.servicedtls[0].serviceid) {
      visitform.encounter["error"] = {
        type: true,
        ...visitform.encounter["error"],
      };
      errorType = true;
    }

    if (!doc?.start_type) {
      visitform.encounter["error"] = {
        start_type: true,
        ...visitform.encounter["error"],
      };
      errorStartType = true;
    }

    if (!doc?.visit_type) {
      visitform.encounter["error"] = {
        visit_type: true,
        ...visitform.encounter["error"],
      };
      errorVisitType = true;
    }

    if (!doc?.reason?.[0]?.reasoncode) {
      visitform.encounter["error"] = {
        visit_reason: true,
        ...visitform.encounter["error"],
      };
      errorReason = true;
    }
    if (!doc?.participants?.[0]?.resoucetype) {
      visitform.practitioner["error"] = {
        Resourcetype: true,
        ...visitform.practitioner["error"],
      };
      errorResourseType = true;
    }

    if (!doc?.participants?.[0]?.ressubtype) {
      visitform.practitioner["error"] = {
        Resourcesubtype: true,
        ...visitform.practitioner["error"],
      };
      errorResoursesubtype = true;
    }
    if (!doc?.participants?.[0]?.resouceid) {
      visitform.practitioner["error"] = {
        id_name: true,
        ...visitform.practitioner["error"],
      };
      errorResouceId = true;
    }

    if (
      errorClass ||
      errorType ||
      errorStartType ||
      errorVisitType ||
      errorReason ||
      errorResourseType ||
      errorResoursesubtype ||
      errorResouceId
    ) {
      return false;
    } else {
      return true;
    }
  };

  const successfun = async (files) => {
    let UploadedFiles = [];
    setbtnName(true);
    UploadedFiles = await uploadImage(files);
    // ----------------------------------
    const visitform = props.visitData;
    visitform.setVisitDetails({
      ...visitform,
      uploadFiles: files,
    });

    handleSubmit(UploadedFiles);
  };
  const nextBtnFun = async () => {
    const visitform = props.visitData;

    if (!visitform.encounter?.visit_reason) {
      visitform.encounter["error"] = {
        visit_reason: true,
        ...visitform.encounter["error"],
      };
    }
    if (!visitform.encounter?.visit_type) {
      visitform.encounter["error"] = {
        visit_type: true,
        ...visitform.encounter["error"],
      };
    }
    if (!visitform.helthcare?.type) {
      visitform.helthcare["error"] = {
        type: true,
        ...visitform.helthcare["error"],
      };
    }
    if (!visitform.helthcare?.category) {
      visitform.helthcare["error"] = {
        category: true,
        ...visitform.helthcare["error"],
      };
    }
    if (!visitform.helthcare?.name) {
      visitform.helthcare["error"] = {
        name: true,
        ...visitform.helthcare["error"],
      };
    }
    visitform.setVisitDetails({
      ...visitform,
    });
    if (
      visitform.helthcare?.name?.value &&
      visitform.helthcare?.category?.value &&
      visitform.helthcare?.type?.value &&
      visitform.encounter?.visit_type?.value &&
      visitform.encounter?.visit_reason?.value
    ) {
      handleSubmit();
      // this.setState({ activeId: 2 })
    }
  };
  const setActive = (Id) => {
    // this.setState({ activeId: Id })
  };

  const appointmentread = async () => {
    const appointmentData = await dispatch(
      actions.APPOINTMENT_READ_VISIT({
        appointmentId: propsdata.appointmentId,
      })
    );

    if (propsdata?.isedit) {
      const visitData = await dispatch(
        actions.VISIT_READ({
          encounter_id:
            appointmentData?.payload?.data?.[0]?.encounterid?.[0]?._id,
        })
      );

      let visit = visitData?.payload?.data?.[0];
      setLocationData({
        encounter: {
          visit_type: {
            _id: visit?.visit_type?._id,
            value: visit?.visit_type?.display,
          },
          visit_reason: {
            _id: visit?.reason?._id,
            value: visit?.reason?.display,
          },
          start_type: {
            _id: visit?.start_type?._id,
            value: visit?.start_type?.display,
          },
          class: {
            _id: visit?.class?._id,
            value: visit?.class?.display,
          },
          type: {
            _id: visit?.type?._id,
            value: visit?.type?.longDesc,
          },
          mode: visit?.mode?._id,
        },
        practitioner: {
          clinic: {},
          role: {
            value: visit?.participants?.[0]?.resrole?.display,
            label: visit?.participants?.[0]?.resrole?.display,
            _id: visit?.participants?.[0]?.resrole?._id,
          },
          Resourcetype: {
            value: visit?.participants?.[0]?.resoucetype?.display,
            label: visit?.participants?.[0]?.resoucetype?.display,
            _id: visit?.participants?.[0]?.resoucetype?._id,
          },
          speciality: {
            value: visit?.participants?.[0]?.speciality?.display,
            label: visit?.participants?.[0]?.speciality?.display,
            _id: visit?.participants?.[0]?.speciality?._id,
          },
          type: {
            value: visit?.participants?.[0]?.ressubtype?.display,
            label: visit?.participants?.[0]?.ressubtype?.display,
            _id: visit?.participants?.[0]?.ressubtype?._id,
          },
          id_name: {
            value: visit?.participants?.[0]?.resouceid?.longdesc,
            label: visit?.participants?.[0]?.resouceid?.longdesc,
            _id: visit?.participants?.[0]?.resouceid?._id,
          },
        },
        device: {},
        helthcare: {
          name: {
            value: visit?.servicedtls?.[0]?.serviceid?.longdesc,
            label: visit?.servicedtls?.[0]?.serviceid?.longdesc,
            _id: visit?.servicedtls?.[0]?.serviceid?._id,
          },
        },
        accompained: {},
      });
      setencounterData(visitData?.payload?.data?.[0]);
      setViewFiles(visitData?.payload?.data?.[0]?.attachment);
    }
    if (location?.state?.isEdited) {
      const visitData = await dispatch(
        actions.VISIT_READ({
          encounter_id:
            appointmentData?.payload?.data?.[0]?.encounterid?.[0]?._id,
        })
      );
      // setencounterData(visitData?.payload?.data?.[0]);
      setViewFiles(visitData?.payload?.data?.[0]?.attachment);
    }
    setPersondata(appointmentData?.payload?.data);
    setappointmentData(appointmentData?.payload?.data);
    setreasonID(
      appointmentData?.payload?.data?.[0]?.appointmentReason?.[0]?._id
    );

    const payload = {
      userid: propsdata.personId,
    };
    if (dispatch(actions.GET_RELATED_PERSON)) {
      const data = await dispatch(actions.GET_RELATED_PERSON(payload));
      if (data?.payload?.data) {
        setchoosemember([...data?.payload?.data]);
      }
    }
  };
  // const setIntialReasonData = (reason) => {
  //   let patientData = props.visitData;
  //   let obj = {};
  //   if (reason && Array.isArray(reason) && reason?.length > 0) {
  //     obj = {
  //       value: reason?.[0]?.title,
  //       _id: reason?.[0]?.value,
  //     };
  //   }
  //   patientData.encounter["visit_reason"] = obj;
  //   if (patientData.encounter.error) {
  //     patientData.encounter.error["visit_reason"] = false;
  //   }
  //   patientData.setVisitDetails({
  //     ...patientData,
  //   });
  // };
  const alertHold = () => {
    // this.context.setSnack({
    //   open: true,
    //   severity: AlertProps.severity.success,
    //   msg: "Put on holded",
    //   vertical: AlertProps.vertical.top,
    //   horizontal: AlertProps.horizontal.right,
    // });
  };
  const goBackFn = () => {
    if (
      location?.state?.prevRoute &&
      location?.state?.prevRoute === AppRoutes.assemblePatioentRegister
    ) {
      navigate({
        // pathname: ((this.props.location?.state?.guest) || (this.props?.location?.state?.Ispatient)) ? Routes.confirmBooking : Routes.PatientDashBoard,
        pathname: location?.state?.prevRoute,
        state: {
          isBack: true,
          nextRoute: AppRoutes.patientVisitDetails,
          prevRoute: AppRoutes.patientVisitDetails,
          personId: location?.state?.personId,
          patientData: location?.state?.mobileNumber,
          Ispatient: true,
          appointmentId: location?.state?.appointmentId,
          patientId: location?.state?.patientId,
          patient_id: location?.state?.patient_id,
          patient_key: location?.state?.patient_key,
        },
      });
      return;
    }
    // else {
    //   navigate(AppRoutes.dashboard);
    // }
    //navigate.goBack();
  };

  // get encounter class
  const EncounterClass = async (value) => {
    return await dispatch(
      actions.GET_ENCOUNTER_MASTER_CLASS({
        encType: value?.label,
      })
    );
  };
  const getEncounterClass_ = async (value) => {
    let patientData = props.visitData;

    let obj = {};
    let data = await dispatch(
      actions.GET_ENCOUNTER_MASTER_CLASS({
        encType: value,
      })
    );

    if (
      data?.payload?.data &&
      Array.isArray(data?.payload?.data) &&
      data?.payload?.data?.length > 0
    ) {
      if (!propsdata?.isedit) {
        // data.payload.data.map((val) => {
        //   if (val.shortDesc === "Ambulatory") {
        //     obj = {
        //       value: val.shortDesc,
        //       _id: val.encClsType,
        //     };
        //     patientData.encounter["class"] = obj;
        //     if (patientData.encounter.error) {
        //       patientData.encounter.error["class"] = false;
        //     }
        //     patientData.setVisitDetails({
        //       ...patientData,
        //     });
        //   }
        // });
      } else {
        data.payload.data.map((val) => {
          if (val.shortDesc === "Ambulatory") {
            obj = {
              value: val.shortDesc,
              _id: val.encClsType,
            };
            patientData.encounter["class"] = obj;
            if (patientData.encounter.error) {
              patientData.encounter.error["class"] = false;
            }
            patientData.setVisitDetails({
              ...patientData,
            });
          }
        });
      }
    }
  };
  const constructEncounterTypeObjectData = async (data) => {
    let patientData = props.visitData;
    let obj = {};
    if (
      data?.payload?.data &&
      Array.isArray(data?.payload?.data) &&
      data?.payload?.data?.length > 0
    ) {
      //data.payload.data.map((val) => {
      //   if (val.longDesc === "Outpatient") {
      //     obj = {
      //       value: val.longDesc,
      //       _id: val._id,
      //       encType: val?.encType,
      //     };
      //     patientData.encounter["type"] = obj;
      //     if (patientData.encounter.error) {
      //       patientData.encounter.error["type"] = false;
      //     }
      //     patientData.setVisitDetails({
      //       ...patientData,
      //     });
      //   }
      //});
    }
    if (obj?.encType) {
      await getEncounterClass_(obj?.encType);
    }
  };

  const walkin = propsdata?.walkin || propsdata?.Ispatient;

  const { name } = getUserInfo();

  return (
    <Div id="visit_registration_parent_div" className="light-background-color">
      {!propsdata?.isedit ? (
        <TopNavbar
          goBack={goBackFn}
          initialFiles={props.visitData?.uploadFiles || viewFiles || []}
          parent_id={"Registervisit_visit_details"}
          alertHold={alertHold}
          activeId={activeId}
          putOnHold={() =>
            putOnHold(
              "undefined",
              //props.location?.state?.appointmentId
              propsdata.appointmentId
            )
          }
          //newUser={props.location?.state?.newUser}
          newUser={propsdata.newUser}
          navClick={setActive}
          successfun={successfun}
          navBarArr={patientVisit}
          title={patientVisitTitle}
          btn={btnName ? "Registering..." : "Register & continue"}
          isAVC={props?.AVCMeeting?.RoomName}
          setFiles={setFiles}
          setviewFiles={setViewFiles}
          isVisit={false}
          isVisitUploadIcon={true}
        />
      ) : (
        <TopNavbar
          view={propsdata?.isRevise ? false : propsdata?.isedit}
          isViewMode={propsdata?.isRevise ? false : propsdata?.isedit}
          newUser
          parent_id={"Registervisit_visit_details"}
          alertHold={alertHold}
          activeId={activeId}
          putOnHold={() =>
            putOnHold("undefined", location?.state?.appointmentId)
          }
          navClick={setActive}
          successfun={successfun}
          //navBarArr={patientVisit}
          title={!propsdata?.isRevise ? "View Visit" : "Revise Visit"}
          viewYes={propsdata?.isRevise ? false : propsdata?.isedit}
          btn={!propsdata?.isRevise ? "View Visit" :btnName ? "Revise Visit...": "Revise Visit"}
          setFiles={setFiles}
          setviewFiles={setViewFiles}
          initialFiles={viewFiles}
          isVisit={false}
        />
      )}
       <Div  
       style={{
          height: "calc(100vh - 128px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
          overflow: "auto",
          display: "block",
          alignItems: "center",
          justifyContent: "center",
        }}>
      <Loading loading={loading}>
        {Persondata?.length > 0 && !propsdata?.isedit ? (
          <Div
            id="Registervisit_visit_details_patientlist_div"
            className="rv-list-view"
            inLineStyles={{ padding: 10 }}
          >
            {Persondata?.[0] && (
              <PatientListView
                parent_id={"Registervisit_visit_details"}
                Persondata={Persondata?.[0]}
                personvisitedDataNames={
                  location?.state?.personvisitedDataNames?.[0] ?? {}
                }
                details={location?.state?.details ?? {}}
                contact={location?.state?.contact ?? {}}
                address={location?.state?.address?.[0] ?? {}}
                walkin={walkin}
              />
            )}
          </Div>
        ) : (
          <Row id="financial_patientlist_row">
            {" "}
            <Col
              id="financial_patientlist_col"
              lg={6}
              md={6}
              inLineStyles={{ padding: 5 }}
            >
              <PatientListView
                parent_id={"Registervisit_visit_details"}
                Persondata={Persondata?.[0]}
                noright={true}
              />
            </Col>
            <Col
              id="financial_patientstatus_col"
              lg={6}
              md={6}
              inLineStyles={{ padding: 5 }}
            >
              <PatientStatusCard
                parent_id={"Financial_Registration"}
                EncounterDetails={encounterData}
                pic={
                  "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                }
                titles={[
                  `${encounterData?.encounter_no ?? ""}`,
                  `${encounterData?.type?.longDesc ?? ""}`,
                  `${encounterData?.visit_type?.display ?? ""}`,
                  `${
                    // encount.practitioner_details?.[0]?.speciality?.coding?.[0]
                    //  ?.display ?? ""
                    encounterData?.participants?.[0]?.speciality?.display ?? ""
                  }`,
                ]}
                date={utcTOLocal(
                 encounterData?.encounterdate,
                  "MMMM Do YYYY, h:mm a"
                )}
                languages={
                  Persondata?.[0]?.communicationLanguage
                    ? Persondata?.[0]?.communicationLanguage?.map(
                        (val, i) =>
                          val?.display +
                          (Persondata?.[0]?.communicationLanguage.length - 1 ===
                          i
                            ? ""
                            : ",")
                      )
                    : []
                }
                padding
              />
            </Col>
          </Row>
        )}

        {activeId === 1 && (
          <Div
            id="Registervisit_visit_details_id_one_parent_div"
            style={{
              padding: "3px 15px",
            }}
          >
            <EncounterInfo
              parent_id={"Registervisit_visit_details"}
              Persondata={Persondata}
              allMasters={allMasters}
              getEncounterClass={EncounterClass}
              // encounterType={
              //   getEncounterType?.data && Array.isArray(getEncounterType?.data)
              //     ? getEncounterType?.data
              //     : []
              // }
              encounterClass={
                getEncounterClass?.data &&
                Array.isArray(getEncounterClass?.data)
                  ? getEncounterClass?.data
                  : []
              }
              appointmentData={appointmentData}
              reasonID={reasonID}
              isEdit={propsdata?.isRevise ? false : propsdata?.isedit}
              isRevise={propsdata?.isRevise}
              encount={encounterData}
              walkin
              loadDefaultData={loadDefaultData}
              locationData={locationData}
              isEdited={location?.state?.isedit}
              encounterType={encounterType}
            />
            {/* {walkin && (
              <BookingDetails
                parent_id={"Registervisit_visit_details"}
                name={name}
                personvisitedDataNames={
                  location?.state?.personvisitedDataNames?.[0] ?? {}
                }
                Persondata={Persondata}
                appointmentData={appointmentData}
              />
            )} */}
            <BookingDetails
              parent_id={"Registervisit_visit_details"}
              name={name}
              personvisitedDataNames={
                location?.state?.personvisitedDataNames?.[0] ?? {}
              }
              Persondata={Persondata}
              appointmentData={appointmentData}
            />
            <Pactitioner
              parent_id={"Registervisit_visit_details"}
              Persondata={Persondata}
              appointmentData={appointmentData}
              walkin={walkin}
              allMasters={allMasters}
              choosemember={choosemember}
              isEdit={propsdata?.isRevise ? false : propsdata?.isedit}
              encount={encounterData}
              isRevise={propsdata?.isRevise}
              loadDefaultResourcetype={loadDefaultResourcetype}
              loadDefaultLocationType={loadDefaultLocationType}
              locationData={locationData}
              isEdited={location?.state?.isedit}
            />
            <DeviceDetails
              parent_id={"Registervisit_visit_details"}
              walkin={walkin}
              appointmentData={appointmentData}
              allMasters={allMasters}
              isEdit={propsdata?.isRevise ? false : propsdata?.isedit}
              encount={encounterData}
              isRevise={propsdata?.isRevise}
            />
            {propsdata?.isRevise && (
              <ReasonDetails
                parent_id={"Registervisit_visit_details"}
                walkin={walkin}
                appointmentData={appointmentData}
                allMasters={allMasters}
                label={"Reason for Revision"}
                view={false}
              />
            )}
          </Div>
        )}

        {activeId === 2 && (
          <Div
            id="Referral_Registration_referral_id_one_parent_div"
            style={{
              padding: "3px 15px",
            }}
          >
            <RefLetter Persondata={Persondata} />
            <RefFrom
              walkin={walkin}
              choosemember={choosemember}
              allMasters={allMasters}
            />
            <RefTo
              Persondata={Persondata?.Person?.[0]}
              walkin={walkin}
              appointmentData={appointmentData}
              allMasters={allMasters}
            />
            <RefDetails walkin={walkin} allMasters={allMasters} />
            <RefNote />
            <Div
              id="Referral_Registration_referral_go_back_div"
              className="p-continue-action"
            >
              <Button
                id="Referral_Registration_referral_go_back_button"
                inLineStyles={styles?.outlinedButton}
                onClick={() => setactiveId(1)}
              >
                Go Back
              </Button>
            </Div>
          </Div>
        )}
      </Loading>
      </Div>
    </Div>
  );
};

export default withAppBar(withAllContexts(VisitRegistration));
