import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Button,
  Box,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  CardActions,
  CardHeader,
  Stack,
  Pagination,
  TextField,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  Bed as BedIcon,
  CheckCircle as DoneIcon,
  Notifications as NotificationsIcon,
  Event as EventIcon,
  Assignment as AssignmentIcon,
  WbSunny as WbSunnyIcon,
  PermIdentity as PermIdentityIcon,
  AssignmentInd as AssignmentIndIcon,
  LooksOne as DialysisIconFirstHour,
  LooksTwo as DialysisIconSecondHour,
  Looks3 as DialysisIconThirdHour,
  Healing as HealingIcon,
  WaterDrop as DialysisIcon,
  Cake as CakeIcon,
  Male as MaleIcon,
  Female as FemaleIcon,
    FilterList as FilterListIcon,
} from "@mui/icons-material";
// import theme from "../../theme";
import TagIcon from '@mui/icons-material/Tag';
import PaymentIcon from '@mui/icons-material/Payment';
import PaidIcon from '@mui/icons-material/Paid';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import CloseIcon from "@mui/icons-material/Close";
import HDDialogBox from "../../../../patientDetail/TreatmentProcedure";
import { AlertContext } from "../../../../../context";
import {  dialysisStates, timeElapsedShowStates } from "../../../../../utils";
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';


const statusColors = {
  "New Appointment": { background: "#64b5f6", text: "#ffffff" },
  "Checked In": { background: "#81c784", text: "#ffffff" },
  "In Progress": { background: "#e57373", text: "#ffffff" },
  "Dialysis Completed": { background: "#179e8e", text: "#ffffff" },
  "Billed": { background: "#f06292", text: "#ffffff" },
  "Check Out": { background: "#9575cd", text: "#ffffff" },
  "Pause": { background: "#ffb74d", text: "#ffffff" },
  "Cancelled": { background: "#9e9e9e", text: "#ffffff" },
  "No Show": { background: "#9e9e9e", text: "#ffffff" },
};

// Reusable component for Status Chip
const StatusChip = ({ label }) => {
  const { background, text } = statusColors[label] || {
    background: "#e0e0e0",
    text: "#000000",
  };

  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: background,
        borderRadius: 2,
        color: text,
        fontSize: 18,
        //fontWeight: 'bold'
      }}
    />
  );
};
function ElapsedTime({ epochTime }) {
  const [elapsedTime, setElapsedTime] = useState(calculateElapsedTime(epochTime));
  function calculateElapsedTime(epochTime) {
    const currentTime = new Date();
    const startTimeDate = new Date(epochTime * 1000); // Convert epoch time to milliseconds
  
    const elapsedTime = currentTime - startTimeDate;
    const elapsedHours = Math.floor(elapsedTime / 3600000);
    const elapsedMinutes = Math.floor((elapsedTime % 3600000) / 60000);
    const elapsedSeconds = Math.floor((elapsedTime % 60000) / 1000);
  
    const formattedTime = `${String(elapsedHours).padStart(2, '0')}:${String(elapsedMinutes).padStart(2, '0')}:${String(elapsedSeconds).padStart(2,   
   '0')}`;
    return formattedTime;
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedTime(calculateElapsedTime(epochTime));
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [epochTime]);

  return elapsedTime;
}
// Component for Patient Card Header
const CardHeaderTitle = ({ patientData}) => {
  
  const { field1, field2, field3, field4, field8 ,duration,queueMetaData} = patientData;

  return (
    <Grid container >
      <Grid item xs={2}>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 60,
            bgcolor: "#fff",
            color: "#179e8e",
            fontSize: 40,
            fontWeight: "bold",
                  }}
         
        >
         <Grid container justifyContent={"center"} >
            {field8 ? field8?.[field8?.length - 1] : <TagIcon />}
          </Grid>
        </Avatar>
      </Grid>
      <Grid item xs={10} sx={{ display: "flex", flexDirection: "column" }} mt={0.6}>
        <Typography variant="button" sx={{ fontSize: "1rem" }} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"} maxWidth={"15vw"} >
          {field1? field1:""}
        </Typography>
        <Box sx={{ display: "flex", gap: 1,mt:0.5}}>
          {field2 ? (
            <Chip
              label={field2}
              size="small"
              sx={{ color:"inherit",bgcolor:"#179e8e"}}
              icon={<AssignmentIndIcon color="inherit"/>}
            />
          ) : (
            <></>
          )}
          {field3 ? (
            <Chip
              label={field3}
              size="small"
              sx={{ color:"inherit",bgcolor:"#179e8e"}}
              icon={<CakeIcon color="inherit"/>}
            />
          ) : (
            <></>
          )}
          {field4 ? (
            <Chip
              label={field4}
              size="small"
              sx={{ color:"inherit",bgcolor:"#179e8e"}}
              icon={field4 === "MALE" ? <MaleIcon color="inherit" /> : <FemaleIcon color="inherit" />}
            />
          ) : (
            <></>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

function previousStep(step) {
  switch (step) {
    case "treatmentChart":
      return null; 
    case "predialysis":
      return "treatmentChart";
    case "intraDialysisBaseline":
      return "predialysis";
    case "intraDialysis1Hour":
      return "intraDialysisBaseline";
    case "intraDialysis2Hour":
      return "intraDialysis1Hour";
    case "intraDialysis3Hour":
      return "intraDialysis2Hour";
    case "postDialysis":
      return "intraDialysis3Hour";
    default:
      return null; 
  }
}



// Component for Patient Card Content
const CardContentDetails = ({ patientData,RefreshLatestQueue }) => {
  const alert = useContext(AlertContext);
  const [treatmentProcedureDialogOpen, setTreatmentProcedureDialogOpen] = React.useState(false);
  const [scrollStep, setScrollStep] = React.useState("treatmentChart"); 
  const { field6, field11, field12, field14, field15, field16, queueMetaData, steps, duration ,statesid} = patientData;
  const treatmentChartRef = useRef(null);
  const preDialysisRef = useRef(null);
  const intraDialysisRef = useRef(null);
  const postDialysisRef = useRef(null);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (scrollStep == "treatmentChart" && treatmentChartRef.current) {
        treatmentChartRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (scrollStep == "predialysis" && preDialysisRef.current) {
        preDialysisRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if ((scrollStep == "intraDialysisBaseline" ||
        scrollStep == "intraDialysis1Hour" ||
        scrollStep == "intraDialysis2Hour" ||
        scrollStep == "intraDialysis3Hour") && intraDialysisRef.current) {
        intraDialysisRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (scrollStep == "postDialysis" && postDialysisRef.current) {
        postDialysisRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
      treatmentChartRef.current = null;
      preDialysisRef.current = null;
      intraDialysisRef.current = null;
      postDialysisRef.current = null;
    };
  }, [treatmentChartRef, preDialysisRef, intraDialysisRef, postDialysisRef, treatmentProcedureDialogOpen]);
  
  let treatmentPermission;
  if (statesid == dialysisStates["New Appointment"] || statesid == dialysisStates["Cancelled"] || statesid == dialysisStates["No Show"]) {
    treatmentPermission = "disabled";
  }else if (statesid == dialysisStates["Check Out"] || statesid == dialysisStates["Pause"]) {
    treatmentPermission = "view";
  } else {
    treatmentPermission = "edit";
  }

  return (
    <Grid container mt={1}>
      <Grid item xs={12} container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={3}>
       
        </Grid>
        <Grid item xs={6} container justifyContent={"center"}>
          <StatusChip label={queueMetaData?.states?.states ? queueMetaData?.states?.states : ""} />
        </Grid>
        <Grid item xs={3}>
          {timeElapsedShowStates?.includes(queueMetaData?.states?.states) && duration ? (
            <Chip
              label={<ElapsedTime epochTime={duration} />}
              size="small"
              icon={<AlarmRoundedIcon color="secondary" />}
            />
            ) : (<></>)}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mt: 1 ,minHeight:"24px"}}
      >
        <Box display="flex" gap={1}>
          {field6 ? (
            <Chip
              label={field6}
              size="small"
              icon={<Grid3x3Icon color="secondary" />}
            />
          ) : (
            <></>
          )}
          {field11 ? (
            <Chip
              label={field11}
              size="small"
              icon={<AssignmentIcon color="secondary" />}
            />
          ) : (
            <></>
          )}
          {field12 ? (
            <Chip
              label={field12}
              size="small"
              icon={<WbSunnyIcon color="secondary" />}
            />
          ) : (
            <></>
          )}
              </Box>
             
          </Grid>
          <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mt: 1,minHeight:"24px" }}
      >
      <Box display="flex" gap={1}>
          {field14 ? (
            <Chip
              label={field14.slice(0, 20)}
              size="small"
              icon={<PaymentIcon color="secondary" />}
              sx={{ maxWidth: "7vw" }}
            />
          ) : (
            <></>
          )}
          {field15 ? (
            <Chip
              label={field15}
              size="small"
              icon={<PaidIcon color="secondary" />}
            />
          ) : (
            <></>
          )}
          {field16 ? (
            <Chip
              label={field16}
              size="small"
              icon={<EventIcon color="secondary" />}
            />
          ) : (
            <></>
          )}
        </Box>
             
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <ToggleButtonGroup fullWidth disabled={treatmentPermission == "disabled"}>
          {[
            { step: "treatmentChart", Icon: AssignmentIcon,name : "Treatment Chart"},
            { step: "predialysis", Icon: HealingIcon ,name : "Pre Dialysis"},
            { step: "intraDialysisBaseline", Icon: DialysisIcon ,name : "Intra Dialysis Baseline"},
            { step: "intraDialysis1Hour", Icon: DialysisIconFirstHour,name : "Intra Dialysis 1st Hour"},
            { step: "intraDialysis2Hour", Icon: DialysisIconSecondHour,name : "Intra Dialysis 2nd Hour"},
            { step: "intraDialysis3Hour", Icon: DialysisIconThirdHour,name : "Intra Dialysis 3rd Hour"},
            { step: "postDialysis", Icon: DoneIcon,name : "Post Dialysis"},
          ].map(({ step, Icon, name }) => {
            let status;
            if (steps?.[0]?.[step]) {
              status = "completed";
            } else if (steps?.[0]?.[step] === false && steps?.[0]?.[previousStep(step)] === true) {
              status = "inProgress";
            } else {
              status = "notStarted";
            }
            let buttonColor;
            if (status === "completed") {
              if (treatmentPermission == "disabled") {
                buttonColor = "#C6C5C5";
              } else {
                buttonColor = "#C7F7C7";
              }
            } else if (status === "notStarted") {
                buttonColor ="";
            } else if (status === "inProgress") {
              if (treatmentPermission == "disabled") {
                buttonColor = "#DCDCDC";
              } else {
                buttonColor = "#FFDCB0";
              }
            }
            return (
              <Tooltip title={name} placement="top">
                <ToggleButton
                  key={step}
                  value={step}
                  selected={status === "completed"}
                  sx={{
                    flexGrow: 1,
                    padding: "8px",
                    backgroundColor: buttonColor,
                    "&.Mui-selected": {
                      backgroundColor: buttonColor,
                    },
                    "&:hover": {
                      backgroundColor: buttonColor,
                    },
                  }}
                  onClick={() => {
                      setTreatmentProcedureDialogOpen(!treatmentProcedureDialogOpen);
                      setScrollStep(step);
                  }}
                >
                  <Icon color={treatmentPermission == "disabled" ? "disabled" :status === "completed"? "success":status == "inProgress"? "warning": "" }/>
                  </ToggleButton>
                </Tooltip>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
        {/* treatment prodecure dialog */}
        <Dialog
        open={treatmentProcedureDialogOpen}
        onClose={() => setTreatmentProcedureDialogOpen(false)}
        fullWidth={"70%"}
        maxWidth={"70%"}
        style={{ zIndex: 2 }}
        PaperProps={{
          sx: {
            //backgroundColor: theme.palette.primary.light, // Set the background color here
            color: "black",
            // Optional: set text color
          },
        }}
      >
        <DialogTitle sx={{ m: 0, px: 4 }}>
          <Typography variant="h6" fontWeight={700} >HD TREATMENT</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setTreatmentProcedureDialogOpen(false)}
            size="large"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <HDDialogBox patientData={patientData} setTreatmentProcedureDialogOpen={setTreatmentProcedureDialogOpen}
            treatmentChartRef={treatmentChartRef} preDialysisRef={preDialysisRef} intraDialysisRef={intraDialysisRef} postDialysisRef={postDialysisRef} RefreshLatestQueue={RefreshLatestQueue}
            treatmentPermission={treatmentPermission}
          />
        </DialogContent>

      </Dialog>
    </Grid>
  );
};

// Component for Dialysis Actions
const DialysisActions = ({ patientData, actionButtonClick }) => {
    const { action } = patientData;
  return (
    <Box display="flex" justifyContent="center" width="100%">
          <Stack direction="row" spacing={2}>
              {action && action?.length > 0 ?
                  (<>
                      {action.map((action, index) => (
                        <Button
                          key={index}
                          size="small"
                          style={{
                              backgroundColor: action.bgcolor ? action.bgcolor : "white",
                              color: action.bgcolor ? "white" : "#179e8e",
                              outline: action.bgcolor ? "" : "1px solid #179e8e",
                              padding: "5px 10px",
                          }}
                          onClick={() =>
                          actionButtonClick(patientData?.ticketId, action, patientData)
                          }
                        >
                          {action.action}
                        </Button>
                      ))}
                  </>
                  )
                  :
                  (
                    <div style={{ height: "34px" }}></div>
                  )}
      </Stack>
    </Box>
  );
};

// Patient Card Component
const DialysisCard = ({ patientData, actionButtonClick,RefreshLatestQueue }) => {
  console.log(patientData, "patientData");
  return (
  <Card >
    <CardHeader
      title={<CardHeaderTitle  patientData={patientData} />}
      sx={{
        backgroundColor: "#179e8e",
        color: "#fff",
        padding: 1,
      }}
    />
    <CardContent sx={{ padding: 0 }}>
        <CardContentDetails patientData={patientData} RefreshLatestQueue={RefreshLatestQueue} />
    </CardContent>
        <CardActions sx={{
            backgroundColor: "#E7F5F3",
        }}>
      <DialysisActions  patientData={patientData} actionButtonClick={actionButtonClick} />
    </CardActions>
  </Card>
)};


export default DialysisCard;