/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, Div } from "qdm-component-library";
import LabOrder from "./labOrderFlow/labOrder";
// import { Grid } from "@material-ui/core";
import { Dialysis } from "./dialysis/dialysis";
import ProgressNotes from "./progressNotes";
import TreatmentPlan from "./treatmentPlan/treatmentPlan";
import NursingProcedure from "./nursingProcedure/nursingProcedure";
import VitalSigns from "./vitalSigns";
import Allergy from "./allergyFlows/allergy";
import Diagnosis from "./diagnosisFlow/diagnosis";
import CurrentMedication from "../../../src/components/currentMedication";
import PackageOrder from "./packageOrder/packageOrder";
import ReferalRegOut from "./referalRegOut/referalRegOut";

import {Grid} from "@mui/material";

export const ActionItems = (props) => {

  return (
    <Row inLineStyles={{ paddingLeft: "20px 20px 20px 20px" }}>
      {!props.showTitle && (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "12px" }}
        >
          <Grid container style={{ gap: "10px" }}>
            <Grid>
              <Text inLineStyles={{ fontWeight: 600, fontSize: "18px" }}>
                ACTION ITEMS
              </Text>
            </Grid>
            <Grid container style={{ gap: "10px" }}>
              <ReferalRegOut
                referralId={props?.referralId}
                patientgetData={props.patientgetData}
              />
              <TreatmentPlan
                history={true}
                patientgetData={props.patientgetData}
              />
              <CurrentMedication
                patientgetData={props.patientgetData}
              />
              <ProgressNotes
                patientgetData={props.patientgetData}
              />
              <Dialysis
                dialysisData={props?.dialysisData}
                patientgetData={props.patientgetData}
                getDialysisData={props?.getDialysisData}
                // orgdetails={props?.orgdetails}
                saveTreatment={props?.saveTreatment}
                location={props?.locationdata}
                settreatmentprocedure={props?.settreatmentprocedure}
              />

              <LabOrder
                patientgetData={props?.patientgetData}
              />
              <NursingProcedure
                patientgetData={props.patientgetData}
              />
              <PackageOrder
                patientgetData={props.patientgetData}
              />

             <VitalSigns Encounterdata={props?.patientgetData}  onRegistration={false} />

             <Allergy
                onEditChart={props?.onEditChart}
                Encounterdata={props?.patientgetData}
                onRegistration={false}
              />

              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={props?.saveDiagnosis}
                onEditChart={props?.onEditDiagnosis}
                Encounterdata={props?.patientgetData}
                onRegistration={false}
              />

            </Grid>
          </Grid>
        </Col>
      )}
    </Row>
  );
};
